<template>
  <div id="TablaBibliotecaSpots">
    <div class="row">
      <div class="col-12">
        <card :title="data.title">
          <div v-if="isLoading">
            <scale-loader :loading="true" :color="'#1d8cf8'" :height="'95px'"></scale-loader>
          </div>
          <div v-else>
            <div class="col-md-4">
              <base-button type="success" @click="redirect()">{{$t("modulo_spots.biblioteca_spots.btnAdd")}}</base-button>
            </div>
            <div class="table-responsive">
              <vue-bootstrap4-table :rows="data.rows" :columns="data.columns" :config="config">
                <template slot="empty-results">
                  No se encontraron registros
                </template>

                <template slot="spo_nombre" slot-scope="props">
                  <b-badge v-show="props.row.posicion === 0" variant="info">más reciente</b-badge>
                  <span class="ml-1">{{props.row.spo_nombre}}</span>
                </template>

                <template slot="escuchar" slot-scope="props">
                  <audio :src="props.row.spo_source_dbx" controls></audio>
                </template>

                <template slot="spo_tipo" slot-scope="props">
                  <div v-if="props.row.spo_tipo === 'inst'" >
                    <span :class="'label-' + (props.row.estado)">Institucional</span>
                  </div>
                  <div v-if="props.row.spo_tipo === 'noti'" >
                    <span :class="'label-' + (props.row.estado)">Noticias</span>
                  </div>
                  <div v-if="props.row.spo_tipo === 'prom'">
                      <span :class="'label-'+ (props.row.estado)">Promocional</span>
                  </div>
                </template>

                <template slot="actions" slot-scope="props">
                  <button
                    type="button"
                    class="btn el-tooltip btn-icon btn-fab btn-info btn-sm"
                    aria-describedby="el-tooltip-8889"
                    tabindex="0"
                    title="Editar spot"
                    @click="btnEditar(props.row, $event.target)">
                    <i class="tim-icons icon-pencil"></i>
                  </button>

                  <button
                    type="button"
                    class="btn el-tooltip btn-icon btn-fab btn-danger btn-sm"
                    aria-describedby="el-tooltip-8889"
                    tabindex="0"
                    title="Eliminar spot"
                    @click="btnEliminar(props.row, $event.target)">
                    <i class="tim-icons icon-trash-simple"></i>
                  </button>
                  

                  <!-- <button
                    type="button"
                    title="Resumen"
                    class="btn el-tooltip btn-icon btn-fab btn-success btn-sm"
                    aria-describedby="el-tooltip-8889"
                    v-show="props.row.pautada"
                    tabindex="0"
                    @click="verPautas(props.row, $event.target)">
                    <i class="tim-icons icon-bullet-list-67"></i>
                  </button> -->
                  <!-- <button
                    type="button"
                    title="Certificado de Aire"
                    class="btn el-tooltip btn-icon btn-fab btn-success btn-sm"
                    aria-describedby="el-tooltip-8889"
                    tabindex="0"
                    @click="controlAire(props.row, $event.target)">
                    <i class="tim-icons icon-notes"></i>
                  </button> -->
                </template>
              </vue-bootstrap4-table>
            </div>
          </div>
        </card>
        <div>
          <!--Confirm dialog-->
          <template>
            <vue-confirm-dialog></vue-confirm-dialog>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseTable } from '@/components'
import SpotServices from '../../services/SpotServices'
import VueBootstrap4Table from 'vue-bootstrap4-table'
import ResumenSpotServices from '../../services/ResumenSpotServices'
import moment from 'moment'
import Vue from 'vue'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import UserService from '../../services/UserServices'
import api from '../../services/api'
// const { Dropbox } = require('dropbox')
export default {
  components: {
    BaseTable,
    VueBootstrap4Table,
    ScaleLoader
  },
  data () {
    const i18labels = this.$t('modulo_spots.biblioteca_spots')
    const colnames = i18labels.tabla_spots.columnas
    return {
      data: {
        title: i18labels.titulo,
        columns: [
          {
            label: colnames.spo_nombre,
            name: 'spo_nombre',
            sort: true
          },
          {
            label: colnames.escuchar,
            name: 'escuchar'
          },
          {
            label: colnames.tipo_spot,
            name: 'spo_tipo'
          },
          {
            label: colnames.fechaVenc,
            name: 'spo_fecfin'
          },
          {
            label: colnames.actions,
            name: 'actions'
          }
        ],
        rows: []
      },
      pathAltaSpot: 'Cargá tu spot',
      filesPath: '/Spots/',
      pathSpotFull: null,
      userCodigo: null,
      config: {
        per_page: 5, // numero de filas a mostrar por cada página
        per_page_options: [],
        show_refresh_button: false,
        show_reset_button: false,
        global_search: {
          placeholder: 'Buscar'
        },
        highlight_row_hover: true,
        preservePageOnDataChange: true,
        highlight_row_hover_color: '#003962'
      },
      isLoading: false
    }
  },
  created () {
    this.getCurrentUser()
  },
  mounted () {
    // llamar los metodos que necesito al cargar esta página
    // this.armarRutaSpot();
    this.getSpotsByCli()
    this.i18n = this.$i18n
  },
  methods: {
    getCurrentUser () {
      this.userCodigo = UserService.current().Cliente.split(':')[1][0]
    },
    armarRutaSpot () {
      this.pathSpotFull = api.url() + this.filesPath + this.userCodigo + '/'
    },
    async getLinkDbx (codigoSpot, event) {
      if (event.target.src.length !== 0) return false
      await SpotServices.getLinkShare(codigoSpot).then((res)=>{
        if(resp.length>0){
          this.getSpotsByCli()
        }
      })
    },
    getSpotsByCli () {
      const list = []

      let fechaIni
      let vencimiento
      let diasParaVenc
      let estadoSpot
      const fechaHoy = moment()
      let aux
      let pos = 0
      this.isLoading = true

      SpotServices.getSpots().then(res => {
        const result = res.filter(e => e.spo_usuario == JSON.parse(localStorage.user).unique_name)
        result.forEach(async element => {
          let pautada = await ResumenSpotServices.get(element.spo_codigo);
          fechaIni = moment(element.spo_fecini.substr(0, 10)).format('DD-MM-YYYY')
          vencimiento = moment(element.spo_fecfin.substr(0, [10]))

          if (element.spo_tipo === 'prom') {
            aux = moment(element.spo_fecfin.substr(0, [10])).format('D/M/yyyy')
            diasParaVenc = (vencimiento.diff(fechaHoy, 'hour'))

            if (diasParaVenc > 0) {
              estadoSpot = 'verde'
            } else {
              estadoSpot = 'rojo'
            }
          } else {
            aux = '-'
            if (element.spo_tipo == 'inst') {
              estadoSpot = 'azul'
            } else {
              estadoSpot = 'marron'
            }
          }
          list.push({
            posicion: pos,
            spo_nombre: element.spo_nombre,
            spo_codigo: element.spo_codigo,
            spo_fecini: fechaIni,
            spo_fecfin: aux,
            spo_source: element.spo_source,
            spo_source_dbx: element.spo_source_dbx,
            spo_codcli: element.spo_codcli,
            spo_tipo: element.spo_tipo,
            estado: estadoSpot,
            pautada:pautada.length>0
          })
          pos++
        })
        this.data.rows = list
        this.isLoading = false
      })
    },
    redirect () {
      this.$router.push({
        name: this.pathAltaSpot,
        params:
                          {

                          }
      })
    },
    btnEditar (item, button) {
      this.$router.push({
        name: this.pathAltaSpot,
        params:
                          {
                            idSpot: item.spo_codigo,
                            nombreSpot: item.spo_nombre,
                            tipoSpot: item.spo_tipo,
                            source: item.spo_source,
                            fechaIni: item.spo_fecini,
                            fechaFin: item.spo_fecfin
                          }
      })
    },
    btnEliminar (item, button) {
      this.$confirm(
        {
          message: 'Confirmar para eliminar',
          button: {
            no: 'Cancelar',
            yes: 'Confirmar'
          },
          callback: confirm => {
            if (confirm) {
              this.isLoading = true
              SpotServices.bajaSpot(item.spo_codigo, '0').then(res => {
                this.getSpotsByCli()
              })
            }
          }
        }
      )
    },
    btnBorrarPautas (item) {
      this.$confirm(
        {
          message: 'Confirmar para borrar todas las pautas para este spot. Esto se realizara en todas las programaciones',
          button: {
            no: 'Cancelar',
            yes: 'Confirmar'
          },
          callback: confirm => {
            if (confirm) {
              this.isLoading = true
              SpotServices.BorrarPautados(item.spo_codigo).then(res => {
                this.getSpotsByCli()
              })
            }
          }
        }
      )
    },
    verPautas (item, button) {
      this.$router.push({
        name: 'Resumen',
        params:
                          {
                            codigoSpot: item.spo_codigo,
                            nombreSpot: item.spo_nombre

                          }
      })
    },
    controlAire (item) {
      this.$router.push({
        name: 'Control Aire',
        params: {
          codigoSpot: item.spo_codigo
        }
      })
    }
  }// cierre methods
}
</script>
<style>
</style>
