<template>
  <card :title="$t('notificarPago.titulo')">
    <form  id="frmNotificarPago" accept-charset="UTF-8" role="form" v-on:submit.prevent="guardar" class="form-lock">

      <div class="row">
        <div class="col-md-5 pr-md-1">
          <base-input
            :label="$t('notificarPago.formulario.fecha')"
            type="text"
            :value="fechaCreacion"
            v-model="fechaCreacion"
            disabled
            required>
          </base-input>
        </div>
      </div>

      <div class="mt-4 row">
        <div class="col-md-5 pr-md-1">
          <base-input
            :label="$t('notificarPago.formulario.monto')"
            type="number"
            step="0.01"
            placeholder="Monto"
            v-model="monto"
            required>
          </base-input>
        </div>
      </div>

      <div class="mt-4 row">
        <div class="col-md-5 pr-md-1">
          <label>{{$t('notificarPago.formulario.adjunto')}}</label>
          <b-form-file
            v-model="file"
            :state="Boolean(file)"
            placeholder="Seleccione o arrastre un arhivo aquí"
            drop-placeholder="Suelte el archivo aquí"
            required
            >
          </b-form-file>
        </div>
      </div>

      <div class="mt-4 row">
        <div class="col-md-8 pr-md-1">
          <base-input
            :label="$t('notificarPago.formulario.obs')">
            <textarea rows="4" cols="80"
            class="form-control"
            placeholder="Observación"
            v-model="observacion">
            </textarea>
          </base-input>
        </div>
      </div>

      <div class="mt-4 row">
        <div class="col-md-5 pr-md-1">
          <input class="btn btn-md btn-success"
                    type="submit"
                    value="Enviar"
                  >
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import { BaseAlert } from '@/components'
import NotificationTemplate from '@/components/NotificationPlugin/NotificationTemplate'
import NotificaPagoServices from '../services/NotificarPagoServices'
import moment from 'moment'
import handleError from '../utils/handleErrors'

export default {
  layout: 'default',
  components: {
    BaseAlert
  },

  data () {
    const i18labels = this.$t('notificarPago')
    return {
      textos: {
        alertOk: i18labels.mensajeAlertaOk
      },
      notificacion: { mensaje: '', tipo: 'error' },
      type: ['', 'danger', 'success'],
      notifications: {
        topCenter: false
      },
      notifIconExc: 'tim-icons icon-alert-circle-exc',
      notifIconOk: 'tim-icons icon-check-2',
      fechaCreacion: '',
      file: null,
      monto: null,
      observacion: ''

    }
  },
  methods: {
    guardar () {
      const formdata = new FormData()
      formdata.append('file', this.file)

      const montoFloat = parseFloat(this.monto)
      const obs = '<p>' + this.observacion + '</p>'
      let codInformePago

      NotificaPagoServices.subirComprobante(formdata).then(res => {
        // LLamar al service que usa el stored sp_notificarPago
        const nombreArchivo = res
        NotificaPagoServices.notificarPago(montoFloat, this.fechaCreacion, nombreArchivo, obs).then(res => {
          codInformePago = res.paginf_codigo
          this.notificacion.mensaje = this.textos.alertOk + codInformePago

          this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconOk, 2)

          this.$router.push({ path: 'dashboard' })
        })
      })
        .catch(err => {
          this.notificacion.mensaje = handleError.errorApi(err)
          console.log(this.notificacion.mensaje)
          this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconExc, 1)
        })
    },
    redirectTo (pathComponent) {
      this.$router.push({ path: pathComponent })
    },
    notifyVue (verticalAlign, horizontalAlign, message, icono, tipo) {
      const color = tipo
      this.$notify({
        message: message,
        component: NotificationTemplate,
        icon: icono,
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 15000
      })
    },
    inicializar () {
      this.fechaCreacion = moment().format('YYYY-MM-DD HH:mm')
    }
  },
  mounted () {
    this.i18n = this.$i18n
    this.inicializar()
  },
  computed: {

  }
}

</script>
<style>
</style>
