<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card :title="data.textos.title">
          <div v-if="isLoading">
            <scale-loader :loading="true" :color="'#1d8cf8'" :height="'95px'"></scale-loader>
          </div>
          <div v-else>

            <div class="table-responsive">
              <vue-bootstrap4-table :rows="data.rows" :columns="data.columns" :config="config">
                <template slot="empty-results">
                  No se encontraron registros
                </template>

                <template slot="clipro_nombre" slot-scope="props">
                  <div v-if="props.row.clipro_nombre === null">
                    <p>Libre</p>
                  </div>
                </template>

                  <template slot="estado" slot-scope="props">
                    <div>
                        <b-badge :class="'label-estadopedido-'+(props.row.auxEstado)">{{props.row.estado}}</b-badge>
                    </div>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <slot v-if="props.row.auxEstado != 'T'">
                      <button
                        type="button"
                        title="Subir spot"
                        class="btn el-tooltip btn-icon btn-fab btn-success btn-sm"
                        aria-describedby="el-tooltip-8889"
                        tabindex="0"
                        @click="redirectSubirSpot(props.row, $event.target)"
                        >
                        <i class="tim-icons icon-cloud-upload-94"></i>
                      </button>
                    </slot>
                  </template>
              </vue-bootstrap4-table>
            </div>
          </div>
        </card>
        <div>
          <!--Confirm dialog-->
          <template>
            <vue-confirm-dialog></vue-confirm-dialog>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseAlert } from '@/components'
import NotificationTemplate from '../../../components/NotificationPlugin/NotificationTemplate'
import VueBootstrap4Table from 'vue-bootstrap4-table'
import PedidoSpotService from '../../../services/PedidoSpotServices'
import Vue from 'vue'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import moment from 'moment'
import UserService from '../../../services/UserServices'
// const i18n = $t(modulo_sucursales.principal);
// console.log(i18n);

export default {
  components: {
    VueBootstrap4Table,
    ScaleLoader,
    BaseAlert
  },
  data () {
    const i18labels = this.$t('modulo_spots.pedidosOperador')
    const colnames = i18labels.tablaPedidos.columnas
    return {
      data: {
        textos: {
          title: i18labels.tablaPedidos.titulo
        },
        columns: [
          {
            label: colnames.codigoPedido,
            name: 'ps0_codigo',
            sort: false
          },
          {
            label: colnames.nombreCliente,
            name: 'cli_usuari',
            sort: false
          },
          {
            label: colnames.estadoPedido,
            name: 'estado',
            sort: false
          },
          {
            label: colnames.actions,
            name: 'actions'
          }
        ],
        rows: []
      },
      type: ['', 'danger', 'success'],
      notifications: {
        topCenter: false
      },
      notificacion: { mensaje: '', tipo: 'error' },
      ruta: '',
      config: {
        show_refresh_button: false,
        show_reset_button: false,
        pagination: true,
        per_page: 10,
        pagination_info: false,
        global_search: {
          placeholder: 'Buscar'
        },
        highlight_row_hover: true,
        preservePageOnDataChange: true,
        highlight_row_hover_color: '#003962'
      },
      isLoading: false,
      pedidos: [],
      user: null
    }
  },
  created () {
    this.i18n = this.$i18n
    this.getCurrentUser()
    this.cargarConenidoTabla()
  },
  mounted () {
    // llamar los metodos que necesito al cargar esta página
  },
  // cuando se cambia el componente, se limpia el timer
  destroyed () {
  },
  methods: {
    getCurrentUser () {
      const cli = JSON.parse(UserService.current().Cliente)
      this.user = cli
    },
    cargarConenidoTabla () {
      const pedidos = []
      let estado

      PedidoSpotService.GetPedidos().then(res => {
        console.log(res)
        res.forEach(element => {
          estado = this.verificaEstadoPedido(element.ps0_estado)
          pedidos.push({
            ps0_codigo: element.ps0_codigo,
            cli_usuari: element.cli_usuari,
            ps0_codcli: element.ps0_codcli,
            estado: estado,
            auxEstado: element.ps0_estado,
            detallePedido: element.ps0_texto,
            locutor: element.ps0_codlo0,
            tipoSpot: element.ps0_tipoSpot,
            fechaInicio: moment(element.ps0_fecini).format('YYYY-MM-DD'),
            fechaFin: moment(element.ps0_fecfin).format('YYYY-MM-DD')
          })
        })
      })
      this.data.rows = pedidos
    },
    verificaEstadoPedido (estado) {
      let auxEstado

      switch (estado) {
        case 'R':
          auxEstado = 'Esperando respuesta'
          break
        case 'C':
          auxEstado = 'Tenés un nuevo mensaje'
          break
        case 'N':
          auxEstado = 'Tenés un nuevo mensaje'
          break
        case 'A':
          auxEstado = 'Falta grabar'
          break
        case '5':
          auxEstado = 'Cancelado'
          break
        case 'T':
          auxEstado = 'Spot pautado'
          break
      }

      return auxEstado
    },
    redirectSubirSpot (item, button) {
      this.$router.push(
        {
          name: 'Cargar un spot',
          params:
        {
          detallePedido: item.detallePedido,
          codigoPedido: item.ps0_codigo,
          codigoLocutor: item.locutor,
          tipoSpot: item.tipoSpot,
          fechaInicio: item.fechaInicio,
          fechaFin: item.fechaFin,
          cliente: item.cli_usuari,
          clienteCodigo: item.ps0_codcli
        }
        })
    },
    handleError (err) {
      if (!err.response) {
        this.notificacion = 'No se ha recibido respuesta del servidor. Int&eacute;ntelo nuevamente m&aacute;s tarde.'
      } else {
        if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
          this.notificacion.mensaje = err.response.data.errorMessage
        } else {
          switch (err.response.status) {
            case 500:
              this.notificacion.mensaje = 'El servidor no se encuentra disponible.'
              break
            case 403:
              this.notificacion.mensaje = 'Se ha denegado el acceso a esta consulta'
              break
            default:
              /* si obtengo un system.exception, hago un split para poder obtener la primer parte
              *luego, de la primer parte, con un substr para extraer lo que sigue de
              *"system.exception" (17 caracteres)
              */
              let error = []
              error = (err.response.data.split('at'))
              this.notificacion.mensaje = error[0].substr(18)
          }
        }
      }
    },
    notifyVue (verticalAlign, horizontalAlign, message) {
      const color = 1
      this.$notify({
        message: message,
        component: NotificationTemplate,
        icon: 'tim-icons icon-alert-circle-exc',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 0
      })
    }
  }
}
</script>
<style>
</style>
