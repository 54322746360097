import api from './api'

const generoMusicalSubService = {}

// SubGereros musicales
generoMusicalSubService.getSubGeneros = async function () {
  return api.get('/GeneroMusicalSub/').then(res => res.data)
}

export default generoMusicalSubService
