<template>
  <div id="TablaSucursales">
    <div class="row">
      <div class="col-12">
        <card :title="data.textos.title">
          <div v-if="isLoading">
            <scale-loader :loading="true" :color="'#1d8cf8'" :height="'95px'"></scale-loader>
          </div>
          <div v-else>
            <div class="col-md-12">

            <base-alert v-show="avisarLimiteSuc" class="mb-4" :type="alertCantMensaje.tipo">
                <span><b-icon class="display-4" icon="info-circle"></b-icon></span>
                <span id="message-alert" data-notify="message">{{alertCantMensaje.mensaje}}</span>
            </base-alert>

              <base-button
                type="success"
                @click="redirect()"
                :disabled="alcanzoLimiteSuc"
                >{{$t("modulo_sucursales.principal.btnAdd")}}
              </base-button>
            </div>
            <div class="table-responsive">
              <vue-bootstrap4-table :rows="data.rows" :columns="data.columns" :config="config">
                <template slot="empty-results">
                  No se encontraron registros
                </template>

                <template slot="clipro_nombre" slot-scope="props">
                  <div v-if="props.row.clipro_nombre === null">
                    <p>Libre</p>
                  </div>
                </template>

                  <template slot="estado" slot-scope="props">
                      <div v-if="props.row.estado == 0" >
                        <span :id="'popover-' + props.row.clisuc_codigo" class="label label-warning" >Desconectado</span>
                        <b-popover :target="'popover-' + props.row.clisuc_codigo" triggers="hover" placement="top">
                          {{props.row.tiempoDesconectado}}
                        </b-popover>
                      </div>
                      <div v-else>
                          <span class="label label-success">En línea</span>
                      </div>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <button
                      type="button"
                      title="Editar sucursal"
                      class="btn el-tooltip btn-icon btn-fab btn-info btn-sm"
                      aria-describedby="el-tooltip-8889"
                      tabindex="0"
                      @click="btnEditar(props.row, $event.target)">
                      <i class="tim-icons icon-pencil"></i>
                    </button>

                    <button
                      type="button"
                      title="Eliminar sucursal"
                      class="btn el-tooltip btn-icon btn-fab btn-danger btn-sm"
                      aria-describedby="el-tooltip-8889"
                      tabindex="0"
                      @click="btnEliminar(props.row, $event.target)">
                      <i class="tim-icons icon-trash-simple"></i>
                    </button>

                    <button
                      type="button"
                      title="Seleccionar programaciones"
                      class="btn el-tooltip btn-icon btn-fab btn-success btn-sm"
                      aria-describedby="el-tooltip-8889"
                      tabindex="0"
                      @click="redirectSucursalProgramacion(props.row, $event.target)"
                      >
                      <i class="tim-icons icon-bullet-list-67"></i>
                    </button>
                    <button
                     v-show="props.row.audio.length>0"
                      type="button"
                      title="Escuchar Vivo"
                      class="btn el-tooltip btn-icon btn-fab btn-success btn-sm"
                      aria-describedby="el-tooltip-8889"
                      tabindex="0"
                      @click="escuchar(props.row.audio)"
                      >
                      <i class="tim-icons icon-sound-wave"></i>
                    </button>
                  </template>
              </vue-bootstrap4-table>
            </div>
          </div>
        </card>
        <div>
          <!--Confirm dialog-->
          <template>
            <vue-confirm-dialog></vue-confirm-dialog>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseAlert } from '@/components'
import NotificationTemplate from '../components/NotificationPlugin/NotificationTemplate'
import SucursalServices from '../services/SucursalServices'
import ClienteServices from '../services/ClienteServices'
import VueBootstrap4Table from 'vue-bootstrap4-table'
import sucursalService from '../services/SucursalServices'
import Vue from 'vue'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import moment from 'moment'
import UserService from '../services/UserServices'
// const i18n = $t(modulo_sucursales.principal);
// console.log(i18n);

export default {
  components: {
    VueBootstrap4Table,
    ScaleLoader,
    BaseAlert
  },
  data () {
    const i18labels = this.$t('modulo_sucursales.principal')
    const colnames = i18labels.tabla.columnas
    return {
      name: 'TablaSucursales',
      data: {
        textos: {
          title: i18labels.titulo,
          alertCantSucursales: i18labels.alertCantSucursales,
          alcanzoLimiteSucursales: i18labels.alcanzoLimiteSucursales
        },
        columns: [
          {
            label: colnames.clisuc_nombre,
            name: 'clisuc_nombre',
            sort: true
          },
          {
            label: colnames.username,
            name: 'username',
            sort: true
          },
          {
            label: colnames.password,
            name: 'password'
          },
          {
            label: colnames.progMusica,
            name: 'clipro_nombre'
          },
          {
            label: colnames.progSpot,
            name: 'progSpot'
          },
          {
            label: colnames.estado,
            name: 'estado'
          },
          {
            label: colnames.actions,
            name: 'actions'
          }
        ],
        rows: []
      },
      type: ['', 'danger', 'success'],
      notifications: {
        topCenter: false
      },
      notificacion: { mensaje: '', tipo: 'error' },
      ruta: '',
      config: {
        show_refresh_button: false,
        show_reset_button: false,
        pagination: true,
        per_page: 10,
        pagination_info: false,
        global_search: {
          placeholder: 'Buscar'
        },
        highlight_row_hover: true,
        preservePageOnDataChange: true,
        highlight_row_hover_color: '#003962'
      },
      timer: null,
      isLoading: false,
      sucursales: [],
      avisarLimiteSuc: false,
      alcanzoLimiteSuc: false,
      alertCantMensaje: {
        mensaje: '',
        tipo: ''
      },
      user: null
    }
  },
  created () {
    this.i18n = this.$i18n
    this.getCurrentUser()
    this.cliSucursalesGetByCli()
    this.verificaPrefijo()
  },
  mounted () {
    // llamar los metodos que necesito al cargar esta página
    this.actualizarEstadoSucursal()
  },
  // cuando se cambia el componente, se limpia el timer
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    getCurrentUser () {
      const clienteLogueado = JSON.parse(UserService.current().Cliente)
      this.user = clienteLogueado
    },
    cliSucursalesGetByCli () {
      this.isLoading = true
      SucursalServices.getcliSucursalByCliente().then((res) => {
        this.sucursales = res

        const cantSucursales = this.sucursales.length

        this.chequearLimiteSucursales(cantSucursales)

        this.cargarConenidoTabla()

        this.isLoading = false
      })
        .catch(err => {
          this.handleError(err)
          this.notifyVue('top', 'center', this.notificacion.mensaje)
        })
    },
    escuchar(url){
      const audio = new Audio(url);
      audio.play();
    },
    cargarConenidoTabla () {
      const auxRes = []
      let progSpot
      // Armo un json auxiliar agregando, por cada sucursal traida, el estado
      this.sucursales.forEach(element => {
        auxRes.push({
              adm_NroIntentos: element.adm_NroIntentos,
              adm_fechaDesde: element.adm_fechaDesde.substr(0, [10]),
              cli_codigo: element.cli_codigo,
              cli_usuari: element.cli_usuari,
              clipro_nombre: element.clipro_nombre,
              clisuc_codigo: element.clisuc_codigo,
              clisuc_nombre: element.clisuc_nombre,
              clisuc_numeroSucursal: element.clisuc_numeroSucursal,
              password: element.password,
              sucpgr_codigoSucursal: element.sucpgr_codigoSucursal,
              username: element.username,
              estado: 0,
              progSpot: 0,
              tiempoDesconectado: 0,
              permisoSpot: element.clisuc_permisoSpotPropio,
              audio:element.clisuc_idSucursal==null?'':`https://sonic.dattalive.com:${element.clisuc_idSucursal}/stream.aac`
            })
         })
         auxRes.forEach(sucursal=>{
          SucursalServices.estadoSucursal(sucursal.username).then(async (estadoSucu)=>{
             sucursal.estado = estadoSucu.estado
             sucursal.tiempoDesconectado=this.tiempoDesconectado(estadoSucu.diferencia)
             try {
               progSpot = await sucursalService.getprogSpotBySuc(sucursal.clisuc_nombre)
               sucursal.progSpot = progSpot[0].clipro_nombre
              
             } catch (error) {
              console.log(error)
             }
             return sucursal
          }).then((rs)=>{
            
            this.data.rows.push(rs)
          })
         })

         this.data.rows
        
    
         
      
    },
    verificaPrefijo () {
      ClienteServices.getClienteByUsername().then(response => {
        /* Si tiene prefijo definido hago el ruteo hacia el componente Datos de la sucursal
        * Caso contrario el ruteo se hace con la page
        * Aclaración.. el ruteo lo hago por medio del nombre de la página para poder enviar props. Si lo hago
        * por medio del path, no se puede enviar props
        */
        if (response.cli_prefijo != null) {
          this.ruta = 'Datos de la sucursal'
        } else {
          this.ruta = 'Creá una nueva sucursal'
        }
      })
    },
    redirect (item, button) {
      this.$router.push(
        {
          name: this.ruta,
          params:
        {
          idSucursal: 0,
          nombreSucursal: '',
          sucUsuario: ''
        }
        })
    },
    btnEditar (item, button) {
      this.$router.push(
        {
          name: 'Datos de la sucursal',
          params:
        {
          idSucursal: item.clisuc_codigo,
          nombreSucursal: item.clisuc_nombre,
          sucUsuario: item.username
        }
        })
    },
    btnEliminar (item, button) {
      this.$confirm(
        {
          message: 'Confirmar para eliminar',
          button: {
            no: 'Cancelar',
            yes: 'Confirmar'
          },
          callback: confirm => {
            if (confirm) {
              this.isLoading = true
              SucursalServices.bajaSucursal(item.clisuc_codigo, item.username).then(res => {
                this.cliSucursalesGetByCli()
                this.isLoading = false
              })
            }
          }
        }
      )
    },
    redirectSucursalProgramacion (item, button) {
      this.$router.push(
        {
          name: 'Programaciones',
          params:
        {
          idSucursal: item.clisuc_codigo,
          nombreSucursal: item.clisuc_nombre,
          sucUsuario: item.username,
          permisoSpot: item.permisoSpot
        }
        })
    },
    actualizarEstadoSucursal () {
      this.timer = window.setInterval(() => {
        this.data.rows.forEach(row => {
          SucursalServices.estadoSucursal(row.username).then(response => {
            row.estado = response.estado
            row.tiempoDesconectado = this.tiempoDesconectado(response.diferencia)
          })
        })
      }, 600000)
    },
    tiempoDesconectado (tiempoDesconectado) {
      let ultimaConexion
      // calculo los días desde la ultima conexión
      const dias = Math.trunc(((tiempoDesconectado) / 60) / 24)

      var date = moment()

      if (tiempoDesconectado === -1) {
        ultimaConexion = 'Nunca se inició sesión'
      } else {
        if (dias === 0) {
          ultimaConexion = 'Última conexión: hoy a las ' + (date.subtract(tiempoDesconectado, 'minutes').format('HH:mm'))
        } else if (dias === 1) {
          ultimaConexion = 'Última conexión: Ayer a las ' + (date.subtract(tiempoDesconectado, 'minutes').format('HH:mm'))
        } else if (dias > 1) {
          ultimaConexion = 'Última conexión: ' + (date.subtract(tiempoDesconectado, 'minutes').format('DD/MM/YYYY HH:mm'))
        }
      }

      return ultimaConexion
    },
    chequearLimiteSucursales (cantSucursales) {
      // buscar con un service el limite de sucursales del cliente
      let limite
      ClienteServices.getClienteByUsername().then(res => {
        limite = res.cli_cantEquipos
        if (cantSucursales >= limite) {
          this.avisarLimiteSuc = true
          this.alcanzoLimiteSuc = true
          this.alertCantMensaje.mensaje = this.data.textos.alcanzoLimiteSucursales
          this.alertCantMensaje.tipo = 'default'
          this.$store.state.limiteSucursal = true
        } else {
          this.$store.state.limiteSucursal = false
        }
      })
    },
    handleError (err) {
      if (!err.response) {
        this.notificacion = 'No se ha recibido respuesta del servidor. Int&eacute;ntelo nuevamente m&aacute;s tarde.'
      } else {
        if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
          this.notificacion.mensaje = err.response.data.errorMessage
        } else {
          switch (err.response.status) {
            case 500:
              this.notificacion.mensaje = 'El servidor no se encuentra disponible.'
              break
            case 403:
              this.notificacion.mensaje = 'Se ha denegado el acceso a esta consulta'
              break
            default:
              /* si obtengo un system.exception, hago un split para poder obtener la primer parte
              *luego, de la primer parte, con un substr para extraer lo que sigue de
              *"system.exception" (17 caracteres)
              */
              let error = []
              error = (err.response.data.split('at'))
              this.notificacion.mensaje = error[0].substr(18)
          }
        }
      }
    },
    notifyVue (verticalAlign, horizontalAlign, message) {
      const color = 1
      this.$notify({
        message: message,
        component: NotificationTemplate,
        icon: 'tim-icons icon-alert-circle-exc',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 0
      })
    }
  }
}
</script>
<style>
</style>
