<template>
  <div>
    <card>
        <form  id="FrmNuevaSucursal" accept-charset="UTF-8" role="form" v-on:submit.prevent="btnGuardar" class="form-lock">
          <slot v-if="pasoWizard === 0">
            <h4 slot="header" class="title">{{titulos.titulo}}</h4>
            <div class="row">
              <div class="col-md-5 pr-md-1">
                <base-input label="Nombre de la sucursal"
                          placeholder="Nombre"
                          v-model="nombreSucursal"
                          required>
                </base-input>
              </div>
            </div>
            <div v-if="idSucursal === 0">
              <h4  class="title">{{titulos.subtituloDatos}}</h4>

              <div class="row">
                <div class="col-md-5 pr-md-1">
                  <b-input-group class="mb-3">
                      <b-input-group-text>{{clientePrefijo}}</b-input-group-text>
                    <b-form-input
                      v-model="nombreUsuarioSucursal"
                      placeholder="Nombre usuario"
                      pattern="(^[A-Za-z0-9]*([A-Za-z0-9_])+[A-Za-z0-9]$)"
                      title="Solo se permiten letras, números y guión bajo. Ejemplos: ejemplo, ejemplo89n, ejemplo8 o ejemplo_8"
                      required
                      autocomplete="new-password"
                    ></b-form-input>
                  </b-input-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5 pr-md-1">
                  <base-input label="Contraseña"
                            type="password"
                            v-model="contrasenia1"
                            placeholder="Contraseña"
                            required
                            autocomplete="new-password"
                            >
                  </base-input>
                </div>
                <div class="col-md-5 px-md-1">
                  <base-input label="Repetir contraseña"
                            type="password"
                            v-model="contrasenia2"
                            placeholder="Repetir contraseña"
                            required
                            autocomplete="new-password"
                            >
                  </base-input>
                </div>
              </div>

              <input
                class="btn btn-md btn-success"
                type="button"
                value="Siguiente"
                @click="siguientePaso"
                :disabled="estadoBoton"
              >
            </div>
            <div v-else>

              <div class="row">
                <div class="col-md-5 pr-md-1">
                  <base-input label="Contraseña"
                            type="password"
                            v-model="contrasenia1"
                            placeholder="Contraseña"
                            autocomplete="new-password"
                            >
                  </base-input>
                </div>
                <div class="col-md-5 px-md-1">
                  <base-input label="Repetir contraseña"
                            type="password"
                            v-model="contrasenia2"
                            placeholder="Repetir contraseña"
                            autocomplete="new-password"
                            >
                  </base-input>
                </div>
              </div>

               <input
                class="btn btn-md btn-success"
                type="button"
                value="Guardar"
                @click="btnGuardar"
                :disabled="estadoBoton"
              >
            </div>

          </slot>
          <slot v-else>

              <h4 class="title mb-4 ">{{titulos.subtituloProgramaciones}}</h4>
              <h5 class="title mb-4">{{titulos.subtituloProgMusica}}</h5>
              <slot v-if="cantProgRadio > 0">
                <div class="options-container-Musica">
                  <div class="row">
                    <div class="col-12 mb-4">

                        <div id="inputRadio">

                          <b-form-group>
                            <b-form-radio-group
                              v-model="radioSelected"
                              :options="optionsRadio"

                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled">
                            </b-form-radio-group>
                          </b-form-group>
                        </div>
                    </div>
                  </div>
                </div>
              </slot>

              <slot v-else>
                <div>
                  <span class="texto-wizard-2 ml-4">No tenés programaciones de música. Creá una desde</span>
                  <b-badge href="#" @click="mostrarModal" class="badge-wizard-2 mb-4" :data-tipoProg ="'rad'" variant="info">acá</b-badge>
                </div>
              </slot>

              <div class="mb-4 border-top border-info"></div>
              <h5 class="title mt-4 mb-4">{{titulos.subtituloProgSpots}}</h5>
              <slot v-if="cantProgSpot > 0">
                <div class="options-container-Spot">
                  <div class="row">
                    <div class="col-12 mb-4">

                        <div id="inputRadio">
                          <b-form-radio-group
                            v-model="spotSelected"
                            :options="optionsSpot"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                          ></b-form-radio-group>
                        </div>
                    </div>
                  </div>
                </div>
              </slot>
              <slot v-else>
                <div>
                  <span class="texto-wizard-2 ml-4">No tenés programaciones de spots. Creá una desde</span>
                  <b-badge href="#" @click="mostrarModal" class="badge-wizard-2 mb-4" :data-tipoProg ="'spo'" variant="info">acá</b-badge>
                </div>
              </slot>

              <div class="mb-4 border-top border-info"></div>
              <h5 class="title mt-4 mb-4">Permiso para subir y programar spots</h5>

                <div class="options-container-Spot">
                  <div class="row">
                    <div class="col-12 mb-4">

                        <div id="inputRadio">
                          <b-form-radio-group
                            v-model="permisoSelected"
                            :options="permisoSpots"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                          ></b-form-radio-group>
                        </div>
                    </div>
                  </div>
                </div>

              <input
                class="btn btn-md btn-info"
                type="button"
                value="Atrás"
                @click="pasoAnterior"
              >

              <input
                class="btn btn-md btn-success ml-4"
                type="submit"
                value="Guardar"
                :disabled="estadoBoton"
              >
          </slot>
        </form>
      <div id="progSpotModals">
        <!--Modal-->
        <modal :show="showModal" :centered="false" body-classes="p-3" :animationDuration="200"  @close="showModal = false">
          <div class="mb-2" slot="header">
              <h3 class="modal-title">Nueva programación de {{tipoProgramacion}}</h3>
          </div>
          <form ref="form" @submit.stop.prevent="handleSubmit">

            <base-input label="Nombre de la programación"
                      placeholder="Nombre"
                      v-model="nombreProgramacion"
                      required>
            </base-input>

          </form>

          <template slot="footer">
            <base-button type="info" class="mt-2" @click="btnGuardarProgModal">Guardar</base-button>
            <base-button type="secondary" class="mt-2" @click="showModal = false">Cerrar</base-button>
          </template>
        </modal>
      </div>
      <b-overlay
        :show="isLoading"
        opacity="0.4"
        no-wrap
        >
        <template #overlay>
          <div class="text-center">
            <scale-loader :loading="'loading'" :color="'#1d8cf8'" :height="'95px'"></scale-loader>
          </div>
        </template>
      </b-overlay>
    </card>
  </div>
</template>

<script>
import SucursalServices from '../../services/SucursalServices'
import UserServices from '../../services/UserServices'
import ClienteServices from '../../services/ClienteServices'
import { BaseAlert } from '@/components'
import NotificationTemplate from '../../components/NotificationPlugin/NotificationTemplate'
import ClienteProgramacion from '../../services/ClienteProgramacionServices'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import Modal from '../../components/Modal.vue'
import SpotServices from '../../services/SpotServices'
import RadioServices from '../../services/RadioServices'

export default {
  layout: 'default',
  components: {
    BaseAlert,
    ScaleLoader,
    Modal
  },

  data () {
    const i18labels = this.$t('modulo_sucursales.AltaSucursal')
    return {
      titulos: {
        titulo: i18labels.titulo,
        subtituloDatos: i18labels.subtituloDatos,
        subtituloProgramaciones: i18labels.subtituloProgramaciones,
        subtituloProgSpots: i18labels.subtituloProgSpots,
        subtituloProgMusica: i18labels.subtituloProgMusica
      },
      nombreSucursal: '',
      nombreUsuarioSucursal: '',
      clientePrefijo: '',
      contrasenia1: '',
      contrasenia2: '',
      notificacion: { mensaje: '', tipo: 'error' },
      type: ['', 'danger'],
      notifications: {
        topCenter: false
      },
      idSucursal: this.$route.params.idSucursal,
      radioSelected: 0,
      optionsRadio: [],
      spotSelected: 0,
      optionsSpot: [],
      isLoading: false,
      pasoWizard: 0,
      showModal: false,
      nombreProgramacion: '',
      tipoProgramacion: '',
      cantProgSpot: null,
      cantProgRadio: null,
      permisoSpots: [
        { name: 'Si', item: 1 }, { name: 'No', item: 0 }
      ],
      permisoSelected: 0
    }
  },
  methods: {
    verificarPermisoAlta () {
      if (this.$store.getters.getLimiteSucursal) {
        if (this.idSucursal === 0 || this.idSucursal === undefined) {
          this.$router.push({ path: 'sucursales' })
        }
      }
    },
    siguientePaso () {
      this.pasoWizard = 1
    },
    pasoAnterior () {
      this.pasoWizard = 0
    },
    btnGuardarProgModal () {
     
      if (this.tipoProgramacion === 'Spot') {
        this.altaProgramacionSpot()
      } else {
        this.altaProgramacionMusica()
      }
    },
    altaProgramacionMusica () {
      // servicio para guardar una programacion de radio
      this.isLoading = true
      // el primer parametro es 0 para crear una nueva
      RadioServices.altaProgramacion(0, this.nombreProgramacion)
        .then(res => {
          this.showModal = !this.showModal
          this.listarProgramacionesRadio()
          this.isLoading = false
        })
        .catch(err => {
          this.handleError(err)
          this.notifyVue('top', 'center', this.notificacion.mensaje)
          this.isLoading = false
        })
    },
    altaProgramacionSpot () {
      // servicio para guardar un parogramacion de spot
      this.isLoading = true
      SpotServices.altaProgramacion(0, this.nombreProgramacion)
        .then(res => {
          // si todo sale ok, redirijo a programaSpot
          this.showModal = !this.showModal
          this.listarProgramacionesSpot()
          this.isLoading = false
        })
        .catch(err => {
          this.handleError(err)
          this.notifyVue('top', 'center', this.notificacion.mensaje)
          this.isLoading = false
        })
    },
    mostrarModal (info) {
      const tipoProg = info.target.getAttribute('data-tipoProg')
      
      if (tipoProg === 'rad') {
        this.tipoProgramacion = 'Música'
      } else {
        this.tipoProgramacion = 'Spot'
      }
      this.showModal = !this.showModal
    },
    btnGuardar () {
      if (this.idSucursal === 0) {
        this.guardarSucursal()
      } else {
        this.editarSucursal()
      }
    },
    guardarSucursal () {
      if (this.radioSelected != 0 && this.spotSelected != 0) {
        if (this.nombreSucursal.length > 0 && this.nombreUsuarioSucursal.length > 0 && this.contrasenia1.length > 0) {
          if (this.contrasenia1 === this.contrasenia2) {
            this.isLoading = true
            SucursalServices.altaSucursal(this.idSucursal, this.nombreSucursal, this.nombreUsuarioSucursal, this.clientePrefijo,
              this.contrasenia1, this.radioSelected, this.spotSelected, this.permisoSelected)
              .then(res => {
                // si todo sale ok, redirijo a sucursales
                this.isLoading = false
                this.$router.push({ path: '/sucursales' })
              })
              .catch(err => {
                this.handleError(err)
                this.notifyVue('top', 'center', this.notificacion.mensaje)
                this.isLoading = false
              })
          } else {
            this.notificacion.mensaje = 'Las contraseñas no coinciden'
            this.notifyVue('top', 'center', this.notificacion.mensaje)
          }
        } else {
          this.notificacion.mensaje = 'Debe completar todos los campos'
          this.notifyVue('top', 'center', this.notificacion.mensaje)
          this.pasoAnterior()// vuelvo al paso anterior del wizard
        }
      } else {
        this.notificacion.mensaje = 'Debe seleccionar las programaciones'
        this.notifyVue('top', 'center', this.notificacion.mensaje)
      }
    },

    getPrefijo () {
      ClienteServices.getClienteByUsername().then(response => {
        this.clientePrefijo = response.cli_prefijo + '_'
      })
    },
    editarSucursal () {
      // Obtengo por parametro el codigo de la sucursal para editar, 0 si es para crear una nueva
      if (this.nombreSucursal.length > 0 && this.idSucursal!=0 && this.contrasenia1.length > 0) {
        if (this.contrasenia1 === this.contrasenia2) {
          this.isLoading = true
          SucursalServices.put(this.idSucursal, this.nombreSucursal, this.nombreUsuarioSucursal, this.contrasenia1)
            .then(res => {
              // si todo sale ok, redirijo a sucursales
              this.isLoading = false
              this.$router.push({ path: '/sucursales' })
            })
            .catch(err => {
              this.handleError(err)
              this.notifyVue('top', 'center', this.notificacion.mensaje)
              this.isLoading = false
            })
        } else {
          this.notificacion.mensaje = 'Las contraseñas no coinciden'
          this.notifyVue('top', 'center', this.notificacion.mensaje)
        }
      } else {
        this.notificacion.mensaje = 'Debe completar todos los campos'
        this.notifyVue('top', 'center', this.notificacion.mensaje)
      }
    },

    getPrefijo () {
      this.isLoading = true
      ClienteServices.getClienteByUsername().then(response => {
        this.clientePrefijo = response.cli_prefijo + '_'
      })
    },
    verificarParametros () {
      const sucNombre = this.$route.params.nombreSucursal
      const sucUsuario = this.$route.params.sucUsuario

      if (this.idSucursal != 0) {
        this.nombreSucursal = sucNombre
        this.nombreUsuarioSucursal = sucUsuario
      }
    },
    listarProgramacionesRadio () {
      const resJson = []
      ClienteProgramacion.listarProgRadios().then(res => {
        res.forEach(element => {
          resJson.push({
            item: element.clipro_codigo,
            name: element.clipro_nombre
          })
        })

        this.optionsRadio = resJson
        this.cantProgRadio = this.optionsRadio.length
        this.isLoading = false
      })
    },
    listarProgramacionesSpot () {
      const resJson = []
      ClienteProgramacion.listarProgSpot().then(res => {
        res.forEach(element => {
          resJson.push({
            item: element.clipro_codigo,
            name: element.clipro_nombre
          })
        })

        this.optionsSpot = resJson
        this.cantProgSpot = this.optionsSpot.length
        this.isLoading = false
      })
    },
    handleError (err) {
      if (!err.response) {
        
        this.notificacion.mensaje = err.response
      } else {
        if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
          this.notificacion.mensaje = err.response.data.errorMessage
        } else {
          
          switch (err.response.status) {
            case 502:
              this.notificacion.mensaje = 'El servidor no se encuentra disponible.'
              break
            default:
              /* si obtengo un system.exception, hago un split para poder obtener la primer parte
                *luego, de la primer parte, con un substr para extraer lo que sigue de
                *"system.exception" (17 caracteres)
                */
              let error = []
              error = (err.response.data.split('at'))
              this.notificacion.mensaje = error[0].substr(18)
          }
        }
      }
    },
    notifyVue (verticalAlign, horizontalAlign, message) {
      const color = 1
      this.$notify({
        message: message,
        component: NotificationTemplate,
        icon: 'tim-icons icon-alert-circle-exc',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 0
      })
    }
  },
  created () {
    this.verificarPermisoAlta()
  },
  mounted () {
    this.i18n = this.$i18n
    this.getPrefijo()
    this.verificarParametros()
    this.listarProgramacionesRadio()
    this.listarProgramacionesSpot()
  },
  computed: {
    estadoBoton () {
      // si no tiene prefijo, no puede enviar el formulario
      let inhabilitado = true
      if (this.clientePrefijo != null) {
        inhabilitado = false
      }
      return inhabilitado
    }
  }
}

</script>
<style>
</style>
