<template>
  <div id="TablaProgramaciones">
    <div class="row">
      <div class="col-12">
        <card :title="data.title">
          <div v-if="isLoading">
            <scale-loader :loading="'loading'" :color="'#1d8cf8'" :height="'95px'"></scale-loader>
          </div>
          <div v-else>
            <div class="table-responsive">
              <vue-bootstrap4-table :rows="data.rows" :columns="data.columns" :config="config">
                <template slot="empty-results">
                  No se encontraron registros
                </template>

                <template slot="facli_estado" slot-scope="props">
                  <div v-if="props.row.facli_estado == 'A'">
                    <span class="label label-warning">Pendiente</span>
                  </div>
                  <div v-else>
                    <div v-if="props.row.facli_estado == 'P'">
                      <span class="label label-info">Pagada</span>
                    </div>
                    <div v-else>
                      <span class="label label-warning">Vencida</span>
                    </div>
                  </div>
                </template>

                <template slot="facli_periodoI" slot-scope="props">
                  {{props.row.facli_periodoI}}
                </template>

                <template slot="facli_monto" slot-scope="props">
                  $ {{props.row.facli_monto}}
                </template>

                <template slot="actions" slot-scope="props">
                  <button
                    type="button"
                    title="Ver factura"
                    class="btn el-tooltip btn-icon btn-fab btn-info btn-sm"
                    aria-describedby="el-tooltip-8889"
                    tabindex="0"
                    @click="verFactura(props.row.facli_archivo, $event.target)">
                    <i class="tim-icons icon-attach-87"></i>
                  </button>
                </template>

              </vue-bootstrap4-table>
            </div>
          </div>
        </card>
        <div>
          <!--Confirm dialog-->
          <template>
            <vue-confirm-dialog></vue-confirm-dialog>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseTable } from '@/components'
import FacturaServices from '../../../services/FacturaServices'
import VueBootstrap4Table from 'vue-bootstrap4-table'
import Vue from 'vue'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import NotificationTemplate from '../../../components/NotificationPlugin/NotificationTemplate'

export default {
  components: {
    BaseTable,
    VueBootstrap4Table,
    ScaleLoader
  },
  data () {
    const i18labels = this.$t('modulo_Facturas.facturaCliente')
    const colnames = i18labels.tabla_facturas.columnas
    return {
      name: 'TablaProgramaciones',
      notificacion: { mensaje: '', tipo: 'error' },
      type: ['', 'danger'],
      notifications: {
        topCenter: false
      },
      data: {
        title: i18labels.titulo,
        columns: [
          {
            label: colnames.facli_estado,
            name: 'facli_estado',
            sort: true
          },
          {
            label: colnames.facli_periodoI,
            name: 'facli_periodoI',
            sort: true
          },
          {
            label: colnames.facli_monto,
            name: 'facli_monto',
            sort: true
          },
          {
            label: colnames.facli_fecven,
            name: 'facli_fecven',
            sort: true
          },
          {
            label: colnames.actions,
            name: 'actions'
          }
        ],
        rows: []
      },
      ruta: '',
      config: {
        per_page: 12, // numero de filas a mostrar por cada página
        per_page_options: [],
        show_refresh_button: false,
        show_reset_button: false,
        global_search: {
          placeholder: 'Buscar'
        },
        highlight_row_hover: true,
        preservePageOnDataChange: true,
        highlight_row_hover_color: '#003962'
      },
      isLoading: false
    }
  },
  mounted () {
    // llamar los metodos que necesito al cargar esta página
    this.getFacturasByCli()
    this.i18n = this.$i18n
  },
  methods: {
    getFacturasByCli () {
      const auxRes = []
      this.isLoading = true
      FacturaServices.getFacturasByCliente().then(res => {
        res.forEach(element => {
          /* armo un json en base al response. haciendo un substr en los elementos de tipo fecha,
          * extrayendo con substr la parte que necesito mostrar
          */
          auxRes.push({
            facli_codigo: element.facli_codigo,
            facli_periodoI: element.facli_periodoI.substr(0, [7]),
            facli_periodoF: element.facli_periodoF,
            facli_monto: element.facli_monto,
            facli_fecalt: element.facli_fecalt,
            facli_estado: element.facli_estado,
            facli_usuario: element.facli_usuario,
            facli_archivo: element.facli_archivo,
            facli_fecven: element.facli_fecven.substr(0, [10])
          })
        })
        // finalmente asigno al array de rows, el arreglo json armado anteriormente
        this.data.rows = auxRes
        this.isLoading = false
      })
        .catch(err => {
          this.handleError(err)
          this.notifyVue('top', 'center', this.notificacion.mensaje)
        })
    },
    verificarEstadoFactura () {

    },
    verFactura (item, button) {
      FacturaServices.verFactura(item).then(res => {
        var file = new Blob([res], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      })
        .catch(err => {
          this.handleError(err)
          this.notifyVue('top', 'center', this.notificacion.mensaje)
        })
    },
    notifyVue (verticalAlign, horizontalAlign, message) {
      const color = 1
      this.$notify({
        message: message,
        component: NotificationTemplate,
        icon: 'tim-icons icon-alert-circle-exc',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 0
      })
    },
    handleError (err) {
      if (!err.response) {
        this.notificacion.mensaje = 'No se ha recibido respuesta del servidor. Int&eacute;ntelo nuevamente m&aacute;s tarde.'
      } else {
        if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
          this.notificacion.mensaje = err.response.data.errorMessage
        } else {
          switch (err.response.status) {
            case 500:
              this.notificacion.mensaje = 'El servidor no se encuentra disponible.'
              break
            case 400:
              this.notificacion.mensaje = 'No se encontró el archivo'
              break
            case 403:
              this.notificacion.mensaje = 'Se ha denegado el acceso a esta consulta'
              break
            default:
              /* si obtengo un system.exception, hago un split para poder obtener la primer parte
              *luego, de la primer parte, con un substr para extraer lo que sigue de
              *"system.exception" (17 caracteres)
              */
              let error = []
              error = (err.response.data.split('at'))
              this.notificacion.mensaje = error[0].substr(18)
          }
        }
      }
    }
  }// cierre methods
}
</script>
<style>
</style>
