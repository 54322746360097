import api from './api'

const clienteProgramacionService = {}

clienteProgramacionService.listarProgRadios = async function () {
  return api.get('/clienteProgramacion/Radio/').then(res => res.data)
}

clienteProgramacionService.listarProgSpot = async function () {
  return api.get('/clienteProgramacion/Spot/').then(res => res.data)
}

export default clienteProgramacionService
