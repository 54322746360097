<template>
  <card>
      <form  id="pedidoSpot" accept-charset="UTF-8" role="form" v-on:submit.prevent="btnGuardar" class="form-lock">
        <h4>{{$t('modulo_spots.pedidosOperador.subirSpot.titulo', {codigoPedido})}}</h4>
        <div class="row">
          <div class="col-md-8 pr-md-1">
            <base-input label="Detalle del pedido">
                <textarea rows="4" cols="80"
                class="form-control"
                v-model="detallePedido"
                disabled
                >
              </textarea>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5 pr-md-1">
            <base-input label="Nombre del spot"
                      placeholder="Nombre"
                      v-model="nombreSpot"
                      required>
            </base-input>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-5 pr-md-1">
            <label>Seleccione el archivo</label>
            <b-form-file
              v-model="file"
              :state="Boolean(file)"
              placeholder="Seleccione o arrastre un arhivo aquí"
              drop-placeholder="Suelte el archivo aquí"
              required
              >
            </b-form-file>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5 pr-md-1">
          <base-input label="Locutor">
            <b-form-select v-model="locutorSelected">
                <option v-for="(locutor, i) in listaLocutores" v-bind:value="locutor.value" :key="i">
                  {{ locutor.texto }}
                </option>
            </b-form-select>
          </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5 pr-md-1">
          <base-input label="Tipo de spot">
            <b-form-select v-model="tipoSpotSelected">
                <option v-for="(tipo, i) in tipoSpot" v-bind:value="tipo.value" :key="i">
                  {{ tipo.texto }}
                </option>
            </b-form-select>
          </base-input>
          </div>
        </div>

        <div v-if="tipoSpotSelected == 'prom'">
          <div class="row">
            <div class="col-md-5 pr-md-1">
              <base-input
                label="Fecha de inicio"
                type="date"
                v-model="fechaInicio">
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5 pr-md-1">
              <base-input
                label="Fecha de finalización"
                type="date"
                v-model="fechaFin">
              </base-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <button class="btn btn-md btn-default" @click="btnVolver">
              Volver
            </button>
            <input
              class="btn btn-md btn-success ml-3"
              type="submit"
              value="Guardar"
            >
          </div>
        </div>
      </form>
      <b-overlay
        :show="isLoading"
        opacity="0.4"
        no-wrap
        >
        <template #overlay>
          <div class="text-center">
            <scale-loader :loading="'loading'" :color="'#1d8cf8'" :height="'95px'" ></scale-loader>
          </div>
        </template>
      </b-overlay>
  </card>
</template>

<script>
import { BaseAlert } from '@/components'
import NotificationTemplate from '../../../components/NotificationPlugin/NotificationTemplate'
import VueBootstrap4Table from 'vue-bootstrap4-table'
import moment from 'moment'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import spotService from '../../../services/SpotServices'
import LocutorService from '../../../services/LocutorServices'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import handleError from '../../../utils/handleErrors'
import api from '../../../services/api'

export default {
  layout: 'default',
  components: {
    BaseAlert,
    VueBootstrap4Table,
    ScaleLoader
  },

  data () {
    const i18labels = this.$t('modulo_spots.pedidosOperador.subirSpot')
    return {
      isLoading: false,
      textos: {
        title: i18labels.titulo,
        alertOk: i18labels.alertas.mensajeAlertaOk
      },
      notifIconExc: 'tim-icons icon-alert-circle-exc',
      notifIconOk: 'tim-icons icon-check-2',
      codigoPedido: 47,
      cliente: null,
      clienteCodigo: null,
      detallePedido: null,
      nombreSpot: null,
      file: null,
      locutorSelected: null,
      tipoSpotSelected: 'prom',
      tipoSpot: [
        { texto: i18labels.tipoSpotSelect.prom, value: 'prom' },
        { texto: i18labels.tipoSpotSelect.inst, value: 'inst' }
      ],
      listaLocutores: [],
      fechaInicio: null,
      fechaFin: null,
      notifications: {
        topCenter: false
      },
      notificacion: { mensaje: '', tipo: 'error' },
      type: ['', 'danger', 'success']
    }
  },
  methods: {

    inicializar () {
      this.obtenerLocutores()
    },
    obtenerLocutores () {
      const aux = [{ texto: 'Por dispobinilidad', value: -1 }]
      const res = [
    {
        "lo0_codigo": 1,
        "lo0_apenom": "Federico",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/z1mk8d5nlugfqqf/demo_1.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    },
    {
        "lo0_codigo": 2,
        "lo0_apenom": "Fernanda",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/n3bhvddna3n8x2r/demo_2.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    },
    {
        "lo0_codigo": 3,
        "lo0_apenom": "Gabriel",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/581txaw7lzlh4v3/demo_3.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    },
    {
        "lo0_codigo": 4,
        "lo0_apenom": "Hernán",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/ktxvgovrfm346oo/demo_4.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    },
    {
        "lo0_codigo": 5,
        "lo0_apenom": "Marcos",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/67cf3f5rt3aiste/demo_5.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    },
    {
        "lo0_codigo": 7,
        "lo0_apenom": "Mauro",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/rgkfvxdnl78uzow/demo_7.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    },
    {
        "lo0_codigo": 8,
        "lo0_apenom": "Mery",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/mnf895okul4zr8h/demo_8.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    },
    {
        "lo0_codigo": 9,
        "lo0_apenom": "Nelson",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/s5pkbjzcmvm2h6a/demo_9.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    },
    {
        "lo0_codigo": 10,
        "lo0_apenom": "Nestor",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/7npzn115h14nkz4/demo_10.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    },
    {
        "lo0_codigo": 11,
        "lo0_apenom": "Nicolás",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/6ctamvxid0xxwiv/demo_11.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    },
    {
        "lo0_codigo": 12,
        "lo0_apenom": "Paola",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/3alo3qks5l6ts7y/demo_12.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    },
    {
        "lo0_codigo": 13,
        "lo0_apenom": "Maru",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/3xpwjqkqojeo7ww/demo_13.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    },
    {
        "lo0_codigo": 14,
        "lo0_apenom": "Sivina",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/uxseq9udj97eg53/demo_14.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    },
    {
        "lo0_codigo": 15,
        "lo0_apenom": "Facundo",
        "lo0_estado": "A",
        "lo0_demo": "https://www.dropbox.com/s/fqo1g1lsn2apc1t/demo_15.mp3?raw=1",
        "lo0_email": "estudiouno@hotmail.com"
    }
]
res.forEach(element => {
           aux.push({
             texto: element.lo0_apenom,
             value: element.lo0_codigo
           })
         })

      //  LocutorService.getLocutores().then(res => {
      //    res.forEach(element => {
      //      aux.push({
      //        texto: element.lo0_apenom,
      //        value: element.lo0_codigo
      //      })
      //    })
      //  })
      this.listaLocutores = aux
      this.isLoading = false
    },
    redirectTo (ruta) {
      this.$router.push({
        path: ruta
      })
    },
    verificarParametros () {
      if (this.$route.params.codigoPedido != undefined) {
        this.codigoPedido = this.$route.params.codigoPedido
        this.detallePedido = this.$route.params.detallePedido
        this.nombreSpot = this.$t('modulo_spots.pedidosOperador.subirSpot.nombreGenericoSpot') + this.$route.params.codigoPedido
        this.locutorSelected = this.$route.params.codigoLocutor
        this.tipoSpotSelected = this.$route.params.tipoSpot
        this.fechaInicio = this.$route.params.fechaInicio
        this.fechaFin = this.$route.params.fechaFin
        this.cliente = this.$route.params.cliente
        this.clienteCodigo = this.$route.params.clienteCodigo
      } else {
        this.$router.push({ path: 'pedidos' })
      }
    },
    btnVolver () {
      this.$router.push({ path: 'pedidos' })
    },
    btnGuardar () {
      this.submitAudioFile()
    },
    submitAudioFile () {
      let fechaDesde
      let fechaHasta

      if (this.selected === 'inst') {
        fechaDesde = moment().format('YYYY-MM-DD')
        fechaHasta = moment('9999-01-01').format('YYYY-MM-DD')
      } else {
        fechaDesde = this.fechaInicio,
        fechaHasta = this.fechaFin
      }

      const formdata = new FormData()
      formdata.append('file', this.file)

      spotService.uploadSpotOperador(formdata, this.clienteCodigo).then(res => {
        // el res contiene la última parte del path donde se guardó el spot
        spotService.guardarSpotOperador(this.nombreSpot, res, fechaDesde, fechaHasta,
          this.tipoSpotSelected, this.cliente, this.clienteCodigo, this.locutorSelected, this.codigoPedido).then(response => {
          // Si da todo ok, mestro mensaje success
          this.notificacion.mensaje = this.textos.alertOk
          this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconOk, 2)

          this.redirectTo('pedidos')
        })
          .catch(err => {
            this.notificacion.mensaje = handleError.errorApi(err)
            spotService.bajaSpot(0, res)
            this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconExc, 1)
          })
      })
        .catch(err => {
          this.notificacion.mensaje = handleError.errorApi(err)
          this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconExc, 1)
        })
    },
    notifyVue (verticalAlign, horizontalAlign, message, icono, tipo) {
      const color = tipo
      this.$notify({
        message: message,
        component: NotificationTemplate,
        icon: icono,
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 20000
      })
    }
  },
  created () {
    this.i18n = this.$i18n
    this.verificarParametros()
  },
  mounted () {
    this.inicializar()
  },
  computed: {

  }
}

</script>
<style>
</style>
