import api from './api'

const clienteService = {}

clienteService.getClienteByUsername = async function () {
  return api.get('/cliente/consultarCliente/').then(res => res.data)
}

clienteService.setPrefijo = async function (nuevoPrefijo) {
  return api.post('cliente/nuevoPrefijo', {
    nuevoPrefijo
  }).then(res => res.data)
}

clienteService.setDatosCuenta = async function (modoRepSpots, codigosDia, horaDesde, horaHasta,
  contraseniaActual, contraseniaNueva, contraseniaNuevaRep) {
  return api.post('cliente/datosCuenta', {
    modoRepSpots, codigosDia, horaDesde, horaHasta, contraseniaActual, contraseniaNueva, contraseniaNuevaRep
  }).then(res => res.data)
}

export default clienteService
