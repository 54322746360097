<template>
  <div id="TablaProgramaciones">
    <div class="row">
      <div class="col-12">
        <card :title="data.title">
          <div v-if="isLoading">
            <scale-loader :loading="true" :color="'#1d8cf8'" :height="'95px'"></scale-loader>
          </div>
          <div v-else>
            <div class="col-md-12">
              <base-button type="success" @click="redirectAlta()">{{$t("modulo_radios.principal.btnAdd")}}</base-button>
            </div>
            <div class="table-responsive">
              <vue-bootstrap4-table :rows="data.rows" :columns="data.columns" :config="config">
                <template slot="empty-results">
                  No se encontraron registros
                </template>

                <template slot="actions" slot-scope="props">
                  <button
                    type="button"
                    title="Editar programación"
                    class="btn el-tooltip btn-icon btn-fab btn-info btn-sm"
                    aria-describedby="el-tooltip-8889"
                    tabindex="0"
                    @click="btnEditar(props.row, $event.target)">
                    <i class="tim-icons icon-pencil"></i>
                  </button>

                  <button
                    type="button"
                    title="Editar programación"
                    class="btn el-tooltip btn-icon btn-fab btn-danger btn-sm"
                    aria-describedby="el-tooltip-8889"
                    tabindex="0"
                    @click="btnEliminar(props.row.clipro_codigo, $event.target)">
                    <i class="tim-icons icon-trash-simple"></i>
                  </button>

                  <button
                    type="button"
                    title="Programá tu música"
                    class="btn el-tooltip btn-icon btn-fab btn-success btn-sm"
                    aria-describedby="el-tooltip-8889"
                    tabindex="0"
                    @click="calendarRedirect(props.row, $event.target)">
                    <i class="tim-icons icon-calendar-60"></i>
                  </button>
                </template>
              </vue-bootstrap4-table>
            </div>
          </div>
        </card>
        <div>
          <!--Confirm dialog-->
          <template>
            <vue-confirm-dialog></vue-confirm-dialog>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseTable } from '@/components'
import RadioServices from '../../services/RadioServices'
import VueBootstrap4Table from 'vue-bootstrap4-table'
import Vue from 'vue'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

export default {
  components: {
    BaseTable,
    VueBootstrap4Table,
    ScaleLoader
  },
  data () {
    const i18labels = this.$t('modulo_radios.principal')
    const colnames = i18labels.tabla_programaciones.columnas

    return {
      name: 'TablaProgramaciones',
      data: {
        title: i18labels.titulo,
        columns: [
          {
            label: colnames.clipro_nombre,
            name: 'clipro_nombre',
            sort: true
          },
          {
            label: colnames.actions,
            name: 'actions'
          }
        ],
        rows: []
      },
      ruta: 'Nueva programación de música',
      config:
      {
        per_page: 5, // numero de filas a mostrar por cada página
        per_page_options: [],
        show_refresh_button: false,
        show_reset_button: false,
        global_search: {
          placeholder: 'Buscar'
        },
        highlight_row_hover: true,
        preservePageOnDataChange: true,
        highlight_row_hover_color: '#003962'
      },
      isLoading: false
    }
  },
  created () {
    // llamar los metodos que necesito al cargar esta página
    this.getProgramacionesByCli()
  },
  methods: {
    getProgramacionesByCli () {
      this.isLoading = true
      RadioServices.getProgramacionesByCliente().then((res) => {
        this.data.rows = res
        this.isLoading = false
      })
    },
    redirectAlta () {
      this.$router.push({
        name: this.ruta,
        params:
                          {
                            codigoProgramacion: 0,
                            nombreProgramacion: ''
                          }
      })
    },
    btnEditar (item, button) {
      this.$router.push({
        name: this.ruta,
        params:
                          {
                            codigoProgramacion: item.clipro_codigo,
                            nombreProgramacion: item.clipro_nombre
                          }
      })
    },
    btnEliminar (item, button) {
      this.$confirm(
        {
          message: 'Confirmar para eliminar',
          button: {
            no: 'Cancelar',
            yes: 'Confirmar'
          },
          callback: confirm => {
            if (confirm) {
              RadioServices.bajaProgramacion(item).then(res => {
                this.getProgramacionesByCli()
              })
            }
          }
        }
      )
    },
    calendarRedirect (item, button) {
      this.$router.push({
        name: 'Programaciones de música',
        params:
                          {
                            codigoProgramacion: item.clipro_codigo,
                            nombreProgramacion: item.clipro_nombre
                          }
      })
    }
  }// cierre methods
}
</script>
<style>
</style>
