import api from './api'

const generoMusicalService = {}

// Gereros musicales
generoMusicalService.getGeneros = async function () {
  return api.get('/GeneroMusical/').then(res => res.data)
}

// GeneroMusicalRadio
generoMusicalService.buscar = async function (idGenMus, idRad, idRit, idTipEmp, idEst) {
  return api.get(`/GeneroMusicalRadio/Buscar/${idGenMus}` + '/' + `${idRad}` + '/' + `${idRit}` + '/' +
  `${idTipEmp}` + '/' + `${idEst}`).then(res => res.data)
}

export default generoMusicalService
