<template>
  <div>
    <div class="row">
      <div :class="`col-lg-${initPauta} col-sm-12`">

        <Card type="chart" v-show="initPauta == 3">
          <!-- <b-form-group v-show="(currentCliente === currentUserName)" id="lb-suc"
            description="Selecciona para cual o todas las sucursales quieres pautar los spots"
            :label="'Sucursales disponibles'" label-for="sucu">
            <b-form-select id="sucu" v-model="spotsProg.for" @change="listarSpotProgramados">
              <option v-bind:value="''">
                Todas las sucursales
              </option>
              <option v-for="(suc, ix) in listaSucursales" :key="ix" v-bind:value="suc.usuario">
                {{ suc.nombreSucursal }}
              </option>
            </b-form-select>
          </b-form-group> -->
          <b-form-group id="lb-slot" label-for="slot" label="Orden de reproducción (slots)">
            <b-form-radio-group id="slot" :options="radioSlots" v-model="spotsProg.slot"></b-form-radio-group>
          </b-form-group>

          <b-form-group id="lb-slot" label-for="slot" label="Spots disponibles">
            <b-form-select v-model="tipoSelected">
              <b-form-select-option v-for="(tipo, ix) in TIPOSPOTS" :value="tipo.value"
                :key="ix">{{ tipo.text }}</b-form-select-option>
            </b-form-select>
            <br>
            <b-input v-model="searchNameSpot" placeholder="Filtrar por nombre de spot"></b-input>
            <!-- <b-list-group id="accordion-spot">
      <b-list-group-item  :draggable="true" v-for="(spot, ix) in filterSpots" :class="`evento-spot-${spot.estado}`" :key="ix" @click="spotsProg.codigoSpot = spot.codSpot" :active="spotsProg.codigoSpot == spot.codSpot" >{{spot.nombreSpot.toUpperCase()}}</b-list-group-item>
    </b-list-group> -->
            <b-table hover small caption-top sticky-header="200px" :items="filterSpots"
              :fields="['Seleccionar', 'nombreSpot']" :select-mode="'single'" responsive="sm" ref="selectableTable"
              selectable @row-selected="selectSpot">
              <!-- Example scoped slot for select state illustrative purposes -->
              <template #cell(Seleccionar)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
            </b-table>
          </b-form-group>
          <b-form-group :label="`Horarios disponible (${horasDisponibles.length - spotsProg.horaDesde.length})`">
            <b-table hover small caption-top sticky-header="200px" :items="calcularHsDisponibles.disponibles"
              :fields="['Seleccionar', 'Horarios']" :select-mode="'multi'" responsive="sm" ref="selectableTable" selectable
              @row-selected="selectHora">
              <!-- Example scoped slot for select state illustrative purposes -->
              <template #cell(Seleccionar)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
            </b-table>
          </b-form-group>


          <b-form-group id="lb-hora-hasta" label-for="hora-hasta" label="Repetir hasta" v-if="stepMinutes > 0">
            <vue-timepicker input-width="100%" id="hora-hasta"
              :hour-range="[[parseInt(spotsProg.horaDesde.split(':')[0]), 23]]" v-model="horaHasta" :minute-interval="5"
              placeholder="Hora hasta" manual-input></vue-timepicker>
          </b-form-group>

          <b-button @click="addPauta()" v-show="spotsProg.codigoSpot!=-1&&spotsProg.horaDesde.length>0">Agregar</b-button>
          
          
          
        </Card>
      </div>
      <div :class="`col-lg-${12 - initPauta} col-sm-12`">

        <Card type="chart" class="text-center">
          
          <div class="d-flex justify-content-center">
            <b-tabs pills card>
              
              <b-tab v-for="(dia, dx) in filtroSemana" :key="dx" @click="spotsProg.numeroDia = dia.value; nroDia = dia.value;initPauta=0"
                :title="dia.dia.substring(0, 2)" :active="spotsProg.numeroDia == dia.value">

              </b-tab>
            </b-tabs>
          </div>
          
          <b-button-toolbar aria-label="actions">
    <b-button-group size="sm" class="mr-1">
        <b-button @click="initPauta=3" v-show="initPauta==0">Nueva</b-button>
        <b-button @click="initPauta=0" v-show="initPauta==3">Cerrar</b-button>
        <b-button v-if="progSpots.filter(spo => spo.codProgSpot == -1).length > 0" @click="savePautas()">Guardar
          ({{ progSpots.filter(spo => spo.codProgSpot == -1).length }})</b-button>
          <b-button @click="eliminarSpot()" v-if="regSelected.length > 0">Eliminar</b-button>      
        <b-button v-if="progSpots.filter(spo => spo.codProgSpot == -1).length > 0" @click="listarSpotProgramados()">Cancelar</b-button>
        <b-button @click="copyPautaByUser()">Copiar pautados {{ spotsProg.for.length!=0?spotsProg.for:currentUserName }}</b-button>
      </b-button-group>
      
        <b-dropdown v-show="currentUserName==currentCliente" class="m-md-2"  :text="`${spotsProg.for.length!=0?'Sucursal: ':'Propietario: '}${spotsProg.for.length!=0?spotsProg.for:currentUserName}`">
          <b-dropdown-item @click="spotsProg.for=''; listarSpotProgramados();">{{currentUserName}}</b-dropdown-item>
          <b-dropdown-item v-for="(suc, ix) in listaSucursales" v-show="spotsProg.for!=suc.nombreSucursal" @click="spotsProg.for=suc.usuario; listarSpotProgramados();" :key="ix">{{ suc.nombreSucursal }}</b-dropdown-item>
        </b-dropdown>
  </b-button-toolbar>
  <b-alert show variant="info" v-show="ExistCopy">Existe una copia de spot pautados para el dia actual. ¿Desea aplicarla? <a href="#" class="alert-link" @click="viewAndSaveCopy()">Aplicar copia</a></b-alert>
          <b-table-simple  hover small caption-top
            responsive sticky-header="500px" id="prog-spots" class="table tablesorter table-bordered">

            <b-thead >

              <b-tr >
                <b-th scope="col" variant="dark" :colspan="`${slot==0?2:1}`" v-for="slot in [0, 1, 2, 3, 4]" :key="slot">Slot {{ slot + 1 }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody id="bodyTableSpots">
              <b-tr v-show="progSpots.length==0"><b-td colspan="5"><h1>Sin registro</h1></b-td></b-tr>
              <b-tr v-for="(hora, index) in horasOcupados" :key="index">
                <b-td stickyColumn variant="dark" class="align-middle" scope="row">{{ hora.substring(0, 5) }}</b-td>
                <b-td class="align-middle" v-for="(slot, indexSlot) in [0, 1, 2, 3, 4]" :key="indexSlot"
                  v-on:drop="dropHandler" :data-slot="slot" :id="hora.substring(0, 5)"
                  :data-horadestino="hora.substring(0, 5)" v-on:dragover="allowDrop">
                  <div class="d-flex justify-content-start align-items-center" >
                    <div class="d-flex flex-row align-items-center bd-highlight mb-3"
                      v-for="(ev, indexEv) in chequearSlotYHora(slot, hora, spotsProg.numeroDia)" :key="indexEv"
                      :data-usuario="ev.usuario" :draggable="isDraggable(ev)" v-on:dragstart="dragHandler"
                      :class="'evento-spot' + '-' + ev.estado" :data-idSpot="ev.codSpot" :data-progSpot="ev.codProgSpot"
                      :id="'popover-' + ev.codProgSpot">
                      <div class="p-2 bd-highlight">
                        <b-avatar :badge="ev.usuario.substring(5, ev.usuario.length).split('_').join(' ')"
                          v-show="currentUserName !== ev.usuario" badge-variant="dark"
                          :text="ev.usuario.split('_')[0]"></b-avatar>
                        <b-avatar :text="ev.usuario.replaceAll('_', ' ')"
                          v-show="currentUserName == ev.usuario"></b-avatar>
                      </div>
                      <div class="p-2 bd-highlight"><span class="bd-highlight text-wrap">{{ ev.nombreSpot }}</span></div>
                      <b-form-checkbox v-if="(currentUserName == ev.usuario || currentUserName === currentCliente)"
                        :key="indexEv" button-variant="danger" @change="addParams(ev)" size="sm" switch></b-form-checkbox>


                    </div>

                  </div>
                  <!-- <b-list-group horizontal="md" >
                        <b-list-group-item v-for="(ev, indexEv) in chequearSlotYHora(slot,hora,spotsProg.numeroDia)" :key="indexEv"
                          :data-usuario="ev.usuario" :draggable="isDraggable(ev)" v-on:dragstart="dragHandler" 
                          :class="'evento-spot' + '-' + ev.estado" :data-idSpot="ev.codSpot"
                          :data-progSpot="ev.codProgSpot" :id="'popover-' + ev.codProgSpot">
                          <div class="content-list">
                            <b-avatar :text="ev.usuario.split('_')[0]" v-show="currentUserName == ev.usuario"></b-avatar>
                            <b-avatar :badge="ev.usuario.split('_')[1]" v-show="currentUserName !== ev.usuario" badge-variant="dark" :text="ev.usuario.split('_')[0] "></b-avatar>
                            <span class="bd-highlight text-wrap">{{ev.nombreSpot}}</span>
                            <b-form-checkbox v-if="(currentUserName == ev.usuario || currentUserName === currentCliente)"   :key="indexEv" button-variant="danger"  :value="ev" size="sm" switch>Eliminar</b-form-checkbox>
                          </div>
                        </b-list-group-item>
                      </b-list-group> -->



                </b-td>
              </b-tr>
            </b-tbody>

          </b-table-simple>
        </Card>
      </div>
    </div>










    <b-overlay :show="isLoading" opacity="0.4" no-wrap>
      <template #overlay>
        <div class="text-center">
          <scale-loader :loading="true" :color="'#1d8cf8'" :height="'95px'"></scale-loader>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import moment from 'moment'
import cliProgramacionSpotServices from '../../services/ClienteProgramacionSpotServices'

import spotService from '../../services/SpotServices'
import clienteProgramacionSpotService from '../../services/ClienteProgramacionSpotServices'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import Modal from '../../components/Modal.vue'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import UserServices from '../../services/UserServices'
import { BaseAlert, Card, BaseInput, BaseRadio } from '@/components'
import NotificationTemplate from '../../components/NotificationPlugin/NotificationTemplate'


export default {
  components: {
    draggable,
    ScaleLoader,
    Modal,
    VueTimepicker,
    BaseAlert,
    BaseInput,
    BaseRadio,
    Card,
    NotificationTemplate

  },
  data() {
    const i18labels = this.$t('modulo_spots.programacion_spot')
    return {
      textos: {
        titulo: i18labels.titulo,
        titulo_cardSucursales: i18labels.titulo_cardSucursales,
        filtros: {
          labelDia: i18labels.filtros.labelDia,
          labelDiar: i18labels.filtros.labelDiar,
          labelHora: i18labels.filtros.labelHora
        },
        referencias: {
          refDrag: i18labels.referencias.refDrag,
          refTipo: {
            text: i18labels.referencias.refTipo.text,
            noti: i18labels.referencias.refTipo.noti,
            inst: i18labels.referencias.refTipo.inst,
            promAct: i18labels.referencias.refTipo.promAct,
            promVen: i18labels.referencias.refTipo.promVen
          }
        },

        popups: {
          extPromo: i18labels.popups.extPromo,
          elimProg: i18labels.popups.elimProg
        },
        modalExtPromo: i18labels.modalExtPromo,
        botones: {
          guardar: i18labels.botones.guardar,
          cerrar: i18labels.botones.cerrar
        },
        modalEliminar: {
          text: i18labels.modalEliminar.text,
          text2: i18labels.modalEliminar.text2,
          confirm: i18labels.modalEliminar.confirm,
          cancel: i18labels.modalEliminar.cancel
        },
        mensajes: {
          textNotValid: i18labels.mensajes.textNotValid
        }
      },
      hoy:moment().day(),
      stepMinutes: 0,
      pautado: 0,
      copiaPautados:[],
      spotsPautados: [],
      spotsConflicto: [],
      spoSelected: {},
      slotSelected: 0,
      selectedSpotProg: 0,
      searchNameSpot: "",
      notificacion: { mensaje: '', tipo: 'error' },
      type: ['', 'danger', 'success', 'info', 'warning', 'default'],
      notifIconExc: 'tim-icons icon-alert-circle-exc',
      notifIconOk: 'tim-icons icon-check-2',
      titulo: '',
      sucursal: this.currentUserName,
      codigoProgramacion: this.$route.params.codigoProgramacion,
      nombreProgramacion: this.$route.params.nombreProgramacion,
      filtroSemana: [
        { dia: i18labels.dias.do, value: 0, notEnabled: false },
        { dia: i18labels.dias.lu, value: 1, notEnabled: false },
        { dia: i18labels.dias.ma, value: 2, notEnabled: false },
        { dia: i18labels.dias.mi, value: 3, notEnabled: false },
        { dia: i18labels.dias.ju, value: 4, notEnabled: false },
        { dia: i18labels.dias.vi, value: 5, notEnabled: false },
        { dia: i18labels.dias.sa, value: 6, notEnabled: false }
      ],
      spotsDisponibles: [],
      progSpots: [],
      fields: [
        { key: "horaDesde", label: "Desde", sortable: true },
        { key: "slot", label: "Slot", sortable: true },
        { key: "sucursal", label: "Sucursal", sortable: true },
        { key: "nombreSpot", label: "Nombre", sortable: true },
      ],
      spotsProg: {
        codigoProgramacion: this.$route.params.codigoProgramacion,
        codigoSpot: -1,
        numeroDia: moment().day(),
        horaDesde: [],
        slot: 0,
        codigoProgSpotDestino: 0,
        for: ''
      },
      horaHasta: null,
      horarios: [],
      horasDisponibles: [],
      horasOcupados: [],
      horaRepetir: [],
      nroDia: moment().day(),
      slotOrigenTemp: 0,
      movHoraTemp: '',
      codigoProgSpotOrigen: 0,
      codigoSpotInsertar: 0,
      tipoSelected: 'inst',//Cambiar por el objeto
      regSelected: [],
      radioSlots: [
        { value: 0, text: "Slot 1" },
        { value: 1, text: "Slot 2" },
        { value: 2, text: "Slot 3" },
        { value: 3, text: "Slot 4" },
        { value: 4, text: "Slot 5" },
      ],
      TIPOSPOTS: [
        {
          text: 'Institucionales',
          value: 'inst'
        },
        {
          text: 'Promocionales',
          value: 'prom'
        },
        {
          text: 'Noticias',
          value: 'noti'
        }
      ],
      repit: false,
      isLoading: false,
      showModal: false,
      nombreSpotModal: null,
      codigoSpotModal: null,
      fechaInicio: '',
      fechaFin: '',
      initPauta: 0,
      listaSucursales: [],
      currentUserName: null,
      currentCliente: null,
      usuariOrigen: ''
    }
  },

  methods: {

    selectHora(items) {
      this.spotsProg.horaDesde = items
    },
    selectSpot(items) {
      debugger
      this.spotsProg.codigoSpot = items[0].codSpot
    },

    isDraggable(ev) {
      return (((this.currentUserName === ev.usuario && ev.admin == 0) || (this.currentCliente === this.currentUserName)))
    },

    getCurrentUser() {
      // obtenemos el username del usuario logueado
      const ususarioLogueado = UserServices.current().unique_name
      this.currentUserName = ususarioLogueado

      // obtengo el cliente (en caso de ser sucursal, obtengo a que cliente pertenece)
      const cli = JSON.parse(UserServices.current().Cliente)
      this.currentCliente = cli.cli_usuari
      this.sucursal = ''

    },
    abrirModal(info) {
      this.codigoSpotModal = parseInt(info.target.getAttribute('data-idSpot'))
      this.nombreSpotModal = info.target.getAttribute('data-nombreSpot')
      const auxFechaIni = info.target.getAttribute('data-fecIni')
      const auxFechaFin = info.target.getAttribute('data-fecFin')
      this.fechaInicio = (moment(auxFechaIni).format('yyyy-MM-DD'))
      this.fechaFin = (moment(auxFechaFin).format('yyyy-MM-DD'))
      this.showModal = !this.showModal
    },
    canDrag() {
      return false
    },
    guardarCambios() {
      spotService.cambiarVencimiento(this.codigoSpotModal, this.fechaFin).then(res => {
        this.showModal = !this.showModal
        this.inicializar()
      })
    },
    verificarParametros() {

      if (this.codigoProgramacion === undefined || this.nombreProgramacion === undefined) {
        this.codigoProgramacion = 0
        this.$router.push({
          name: 'Mis programaciones de spots'
        })
        return false;
      } else {
        this.titulo = this.nombreProgramacion
        this.codigoProgramacion = this.codigoProgramacion
      }
    },
    async listarSpotsDisponibles() {
      let estadoSpot
      const styleEstados = { "prom": ['rojo', 'verde'], "inst": ['azul'], "noti": ['marron'] }
      await spotService.getSpotsBycodCliente()
      this.spotsDisponibles = JSON.parse(localStorage.SpotDisponibles)
        .filter(spo => spo.spo_usuario == JSON.parse(localStorage.user).unique_name)
        .map(spo => {
          const vence = (moment(spo.spo_fecfin).diff(moment(), 'hour') == 0) ? 0 : 1

          estadoSpot = (spo.spo_tipo == 'prom') ? styleEstados[spo.spo_tipo][vence] : styleEstados[spo.spo_tipo][0]
          return {
            nombreSpot: spo.spo_nombre,
            codSpot: spo.spo_codigo,
            tipo: spo.spo_tipo,
            fechaInicio: spo.spo_fecini,
            fechaFin: spo.spo_fecfin,
            usuario: spo.spo_usuario,
            estado: estadoSpot
          }
        });


    },
    setProgramado(element) {
      let estadoSpot = 'locked'
      
        const fechaHoy = moment()
        const estado = {
          inst: ["azul"],
          noti: ["marron"],
          prom: ["rojo", "verde"]
        }
        const promVencida = (moment(element.spo_fecfin).diff(fechaHoy) > 0) ? 0 : 1
        if (this.currentUserName != element.clprsp_usuario && element.clprsp_pautaAdmin == 1) {
          if (this.currentUserName === this.currentCliente) {
            estadoSpot = 'unlock'
          } else {
            estadoSpot = 'locked'
          }
        } else {
          estadoSpot = (element.spo_tipo != 'prom') ? estado[element.spo_tipo][0] : estado[element.spo_tipo][promVencida]
        }

      
      return {
        divid: this.$uuid.v4(),
        codProgSpot: element?.clprsp_codigo,
        nombreSpot: element.spo_nombre,
        codProgramacion: this.codigoProgramacion,
        codSpot: element.spo_codigo,
        tipoSpot: element.spo_tipo,
        numeroDia: element.clprsp_numeroDia,
        horaDesde: moment(element.clprsp_horaDesde, 'HH:mm:ss').format('HH:mm'),
        horaHasta: moment(element.clprsp_horaHasta, 'HH:mm:ss').format('HH:mm'),
        orden: element.clprsp_orden,
        estado: (element.clprsp_codigo == -1) ? 'locked' : estadoSpot,
        usuario: element.clprsp_usuario,
        admin: element.clprsp_pautaAdmin,
      }
    },

    async listarSpotProgramados(server = true) {
      this.isLoading = true
      this.horasDisponibles = []
      this.progSpots = []
      
      await cliProgramacionSpotServices.getProgramacionesByProg(this.codigoProgramacion, '00:00', this.spotsProg.for)
        JSON.parse(localStorage.spotsProgramados).filter((spProg)=>spProg.clprsp_numeroDia==this.spotsProg.numeroDia).forEach((element) => {
          this.progSpots.push(this.setProgramado(element))
        })
      if(this.progSpots.length!=0){
        let init = this.progSpots[0].horaDesde
        let fin = this.progSpots.pop().horaDesde
        this.horarios = this.llenarHorarios(init,fin)
      }  


      
      this.isLoading = false
    },
    llenarHorarios() {
      return this.calcularHsDisponibles.ocupados;
    },
    corregirHora() {
      // Cuando se ingresa solo los minutos o solo la hora, el input envía un HH o mm rn lugar de la hora o los minutos
      // Se extrae las partes del string que contenga "HH" o "mm" y se lo reemplaza por "00".
      const auxHora = this.horaDesde.substr(0, 2)
      const auxMinutos = this.horaDesde.substr(3, 2)
      if (auxHora === 'HH') {
        this.horaDesde = '00:' + auxMinutos
      }
      if (auxMinutos === 'mm') {
        this.horaDesde = auxHora + ':00'
      }
    },
    armarFecha(numeroDia, hora, minutos) {
      const fecha = moment(moment().day(numeroDia)).format('YYYY-MM-DD')
      let min = ''
      let hs = ''
      let fechaReturn = ''

      // Hago esto porque el timeSpan pone solo una cifra en hora o minutos si es menor a 10
      if (hora < 10) {
        hs = '0' + hora
      } else {
        hs = hora
      }

      if (minutos < 10) {
        min = '0' + minutos
      } else {
        min = minutos
      }

      fechaReturn = fecha + ' ' + hs + ':' + min + ':' + '00'
      return fechaReturn
    },
    cambiaDia() {
      //this.llenarHorarios()
      this.listarSpotProgramados()
    },
    canDrop() {
      if (this.progSpots.length < 5) {
        return true
      } else {
        return false
      }
    },
    addParams(params) {
      this.regSelected.push(params)
    },
    eliminarSpot(iparams) {
      let params = iparams || this.regSelected
      this.isLoading = true
      let promises = [], error = []
      params.forEach((p) => {
        promises.push(clienteProgramacionSpotService.eliminarProgSpot(p.codProgSpot, p.usuario))
      })
      Promise.all(promises).then(resp => {
        if (resp.pop() == '') {
          this.listarSpotProgramados().then(() => { this.selected = [] })
          this.isLoading = false
        }
        else {
          this.notifyVue('top', 'center', resp.pop(), this.notifIconExc, 5)
        }

      })
    },
    chequearSlotYHora(slot, hora, dia) {
      let result = this.progSpots.filter(el => el.numeroDia == dia)
        .filter(sp => sp.orden == slot && moment(sp.horaDesde, 'HH:mm').diff(moment(hora, 'HH:mm'), 'minutes') == 0)

      if (result.length == 0) {
        return []
      }

      return result
    },
    getEventHora(hora) {
      const result = []
      let getCondition = (element) => {

        return element.horaDesde == (hora.format('HH:mm') + ':00') &&
          element.orden == (slot - 1)
      }

      let count =
        result.push(

          this.progSpots.filter(element => getCondition(element))

        )

      return result
    },
    allowDrop(ev) {
      ev.preventDefault()
    },

    dragHandler(ev) {

      this.$root.$emit('bv::hide::popover')// cuando se empieza a arrastrar el spot, cierro el popover

      // guardo los datos del día y el horario del spot que se moverá
      this.slotOrigenTemp = ev.composedPath().find(el => el?.dataset?.slot).dataset.slot
      this.movHoraTemp = ev.composedPath().find(el => el?.dataset?.horadestino).dataset.horadestino
      this.codigoProgSpotOrigen = ev.composedPath().find(elem => elem?.dataset?.progspot)?.dataset?.progspot ?? null
      this.usuariOrigen = ev.composedPath().find(elem => elem?.dataset?.usuario)?.dataset?.usuario

    },
    async dropHandler(ev) {
      ev.preventDefault()

      // si es 0, es porque el spot viene de afuera de la tabla,
      if (this.slotOrigenTemp === 0) {
        if (this.currentUserName === this.currentCliente) {
          this.addPauta(ev)
        } else {
          // si el usuario logueado no es role cliente, solo podrá permutar si el spot cargado en el slot elegido está vacío o si le pertenece a ese usuario
          if (this.currentUserName === ev.target.getAttribute('data-usuario') || ev.target.getAttribute('data-usuario') === null) {
            this.agregaUnNuevoSpot(ev)
          } else {
            this.notificacion.mensaje = this.textos.mensajes.textNotValid + ' ' + this.currentCliente

            this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconExc, 5)
          }
        }
      } else {
        if (this.currentUserName === this.currentCliente) {
          this.permutaUnSpot(ev)
        } else {
          // si el usuario logueado no es role cliente, solo podrá permutar si el spot cargado en el slot elegido está vacío o si le pertenece a ese usuario
          if (this.currentUserName === ev.target.getAttribute('data-usuario') || ev.target.getAttribute('data-usuario') === null) {
            this.permutaUnSpot(ev)
          } else {
            this.notificacion.mensaje = this.textos.mensajes.textNotValid + ' ' + this.currentCliente

            this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconExc, 5)
          }
        }
      }
    },
    async agregaUnNuevoSpot(ev) {
      let numSlotDestino
      let horarioSlotDestino

      let codigoProgSpotDestino = this.codigoProgramacion

      // si el usuario logueado es el cliente o si el spot a mover pertenece al usuario logueado puedo realizar la accion
      // if(this.currentUserName === this.currentCliente || this.currentUserName === ev.target.getAttribute("data-usuario")){
      // Si no hay un spot cargado en el slot al que voy a mover
      if (ev.target.id != '') {
        // obtengo el horario del row donde está el slot a donde estoy moviendo
        horarioSlotDestino = ev.composedPath()[2].id
        /* paso un valor que no sea ningun del 1 al 5 (los slots validos) porque en el caso de intercambio entre
          dos slots ocupados, los datos los obtengo en el stored
        */

        numSlotDestino = 7
      } else {
        // obtengo el horario del row donde está el slot a donde estoy moviendo
        horarioSlotDestino = ev.composedPath()[1].id
        // obtengo el numero del slot donde está el spot que voy a mover, y tambien el slot hacia donde voy a mover
        numSlotDestino = ev.composedPath()[0].cellIndex
        codigoProgSpotDestino = 0
      }


      this.listarSpotProgramados()

      // }
    },
    async resolveConflict(horaDesde) {

      let pendientes = this.progSpots.filter(ps => ps.codProgSpot == -1)
      await this.listarSpotProgramados()
      pendientes.forEach(el => this.progSpots.push(el))

      let exist = () => {
        return this.progSpots.filter(el => el.numeroDia == this.spotsProg.numeroDia)
          .find(sp => sp.orden == this.spotsProg.slot && moment(sp.horaDesde, 'HH:mm').diff(moment(this.spotsProg.horaDesde, 'HH:mm'), 'minutes') == 0) !== undefined
      }
      while (exist()) {

        if (this.spotsProg.slot >= 4) {
          let horaAux = moment(this.spotsProg.horaDesde, 'HH:mm').add(5, 'minutes').format("HH:mm")
          this.spotsProg.horaDesde = horaAux
          this.spotsProg.slot = 0
        } else {
          this.spotsProg.slot++
        }
      }
      return moment(this.spotsProg.horaDesde, 'HH:mm')
    },
    async savePautas() {
        this.progSpots
          .filter(spo => spo.codProgSpot == -1)
          .forEach((sp) => {
            this.spotsPautados.push({
              codigoProgramacion: sp.codProgramacion,
              codigoSpot: sp.codSpot,
              numeroDia: sp.numeroDia,
              horaDesde: sp.horaDesde,
              codigoProgSpotDestino: 0,
              slot: sp.orden,
              for: sp.usuario
            })
          })
      
      let presult = await clienteProgramacionSpotService.guardarProgSpot(this.spotsPautados)
      this.spotsPautados = []
      this.copiaPautados = []
      this.spot = []
      await this.listarSpotProgramados()
      this.spotsProg.numeroDia= moment().day()
      this.initPauta = 0
      return presult

    },
    Pautar(dia) {
      this.isLoading = true
     
        for (let hsDesde of this.spotsProg.horaDesde) {
          
          let start = moment(hsDesde.Horarios, 'HH:mm')
          let horaDesde = start.format("HH:mm:ss")
          let horaHasta = start.add(5, 'minutes').format('HH:mm:ss')

            let slot = this.spotsProg.slot

            let spoPautado = {
              clprsp_codigo: -1,
              spo_nombre: this.spotsDisponibles.find(spo => spo.codSpot == this.spotsProg.codigoSpot).nombreSpot,
              spo_codigo: this.spotsProg.codigoSpot,
              spo_tipo: this.spotsDisponibles.find(spo => spo.codSpot == this.spotsProg.codigoSpot).tipo,
              clprsp_numeroDia: dia,
              clprsp_horaDesde: horaDesde,
              clprsp_horaHasta: horaHasta,
              clprsp_orden: slot,
              clprsp_usuario: this.spotsProg.for.length!=0?this.spotsProg.for:this.currentUserName,
              clprsp_pautaAdmin: this.currentUserName != this.spotsProg.for ? 1 : 0
            }
            //this.addParams(this.setProgramado(spoPautado))
            this.progSpots.push(this.setProgramado(spoPautado))
            this.horarios = this.llenarHorarios() 
            this.isLoading = false
            
         
        }
      

    },
    addPauta(ev) {
      
      if (ev) {
        this.spotsProg.slot = parseInt(ev.composedPath().find(el => el?.dataset?.slot).dataset.slot)
        this.spotsProg.horaDesde = ev.composedPath().find(el => el?.dataset?.horadestino).dataset.horadestino
        this.horaHasta = this.spotsProg.horaDesde
      }
      
      this.Pautar(this.spotsProg.numeroDia);
      
      

    },
    async copyPautaByUser(){
      let usuario = this.spotsProg.for.length==0?this.currentUserName:this.spotsProg.for  
      await cliProgramacionSpotServices.getProgramacionesByProg(this.codigoProgramacion, '00:00', this.spotsProg.for)
        JSON.parse(localStorage.spotsProgramados).filter((spProg)=>spProg.clprsp_numeroDia==this.spotsProg.numeroDia&&spProg.clprsp_usuario==usuario).forEach((element) => {
          element.clprsp_codigo = -1
          let dias = this.filtroSemana.map(dia=>dia.value).filter(dia=>dia!=this.spotsProg.numeroDia)
          for(let dia of dias){
            let ocupado = JSON.parse(localStorage.spotsProgramados).filter((spProg)=>spProg.clprsp_numeroDia==dia.value&&spProg.clprsp_horaDesde==element.clprsp_horaDesde&&spProg.clprsp_orden&&element.clprsp_orden).length>0
            if(!ocupado){
              
              element.clprsp_numeroDia = dia
              this.copiaPautados.push(this.setProgramado(element))
            }
          }
        })
       

          
    },
    async viewAndSaveCopy(view=true){
        this.copiaPautados.filter(cp=>cp.numeroDia==this.spotsProg.numeroDia).forEach((cp)=>{
          this.progSpots.push(cp)
        })
      
    },
    async permutaUnSpot(ev) {

      // este dato es el que capturo en el dragHandler

      const composedPath = ev.composedPath()

      let data = {
        codigoProgramacion: parseInt(this.codigoProgramacion),
        codigoProgSpotOrigen: this.codigoProgSpotOrigen,
        codigoProgSpotDestino: composedPath.find(elem => elem?.dataset?.progspot)?.dataset?.progspot ?? null,
        usuarioSpot: composedPath.find(elem => elem?.dataset?.usuario)?.dataset?.usuario ?? this.usuariOrigen,
        numeroDia: parseInt(this.nroDia),
        horaSlotOrigen: this.movHoraTemp,
        horaDesde: composedPath.find(elem => elem?.dataset?.horadestino).dataset.horadestino,
        ordenDestino: 6,
        ordenOrigen: parseInt(this.slotOrigenTemp)
      }
      // si el usuario logueado es el cliente o si el spot a mover pertenece al usuario logueado puedo realizar la accion
      const elTargetId = composedPath.find(el => el.id == `popover-${parseInt(data.codigoProgSpotDestino)}`)?.id ?? ''
      // Si no hay un spot cargado en el slot al que voy a mover
      if (elTargetId == '') {
        data.ordenDestino = parseInt(composedPath.find(elem => elem?.dataset?.slot)?.dataset?.slot)
      }

      /* En caso de que haya un spot en el slot al que voy a mover, le paso en el último parametro el valor
        del slot (restando 1 porque en la BD va del 0 al 4)
      */
      await clienteProgramacionSpotService.permutarProgSpot(data)
        .then(res => {
          this.listarSpotProgramados()
        })
      this.slotOrigenTemp = 0
      this.movHoraTemp = ''
    },

    obtenerSucursales() {
      const sucursales = []
      cliProgramacionSpotServices.getProgSpotSucursal(this.codigoProgramacion)
        .then(res => {
          res.forEach(element => {
            sucursales.push({
              codigoSucursal: element.sucpgr_codigoSucursal,
              nombreSucursal: element.clisuc_nombre,
              usuario: element.clisuc_usuario
            })
          })
          this.listaSucursales = sucursales

        })
    },
    notifyVue(verticalAlign, horizontalAlign, message, icono, tipo) {
      const color = tipo
      this.$notify({
        message: message,
        component: NotificationTemplate,
        icon: icono,
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 20000
      })
    },
    inicializar() {
      this.listarSpotsDisponibles()
      this.obtenerSucursales()
      this.listarSpotProgramados()
    },
    

  },

  created() {
    this.verificarParametros()
    this.getCurrentUser()
  },


  mounted() {
    this.inicializar()
  },
  watch: {

    async nroDia() {
      await this.listarSpotProgramados()
    },

  },
  computed: {
    progSpotsDetails() {
      return this.progSpots.filter(spo => spo.numeroDia == this.spotsProg.numeroDia)
    },
    calcularHsDisponibles() {
      
      let horarios = []
      this.horasDisponibles=[]
      this.horasOcupados=[]
      let hsDesde = moment("00:00", 'HH:mm')
      while (moment("23:55", 'HH:mm').diff(hsDesde, 'minute') >= 0) {
        horarios.push(hsDesde.format('HH:mm'))
        hsDesde = hsDesde.add(5, 'minutes')
      }
      for (let hs of horarios) {
        let isDisponible = this.progSpots?.find(p => p.numeroDia == this.spotsProg.numeroDia && p.horaDesde == hs && p.orden == this.spotsProg.slot)
        if (isDisponible == undefined) {
          this.horasDisponibles.push({ Horarios: hs })
        }
      }
      for (let hs of horarios) {
        let isDisponible = this.progSpots?.find(p => p.numeroDia == this.spotsProg.numeroDia && p.horaDesde == hs)
        if (isDisponible != undefined) {
          this.horasOcupados.push(hs)
        }
      }
      return {ocupados:this.horasOcupados,disponibles:this.horasDisponibles}
    },
    ExistCopy(){
      return this.copiaPautados.filter(cp=>cp.clprsp_numeroDia!=this.spotsProg.numeroDia).length>0
    }
    ,
    filterSpots() {
      return this.spotsDisponibles.filter((spot) => { return (spot.nombreSpot.toLowerCase().includes(this.searchNameSpot.toLowerCase()) && spot.tipo == this.tipoSelected) })
    }
  }
}
</script>
<style scoped></style>
