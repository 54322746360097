<template>
  <card :title="textos.title">
    <b-overlay
      :show="isLoading"
      opacity="0.25"
      no-wrap
      >
      <template #overlay>
        <div class="text-center">
          <scale-loader :loading="true" :color="'#1d8cf8'" :height="'95px'" :mensaje="textoLoader"></scale-loader>
        </div>
      </template>
    </b-overlay>
    <form  id="frmNuevoSpot" accept-charset="UTF-8" role="form" v-on:submit.prevent="guardar" class="form-lock">

      <div class="row">
        <div class="col-md-5 pr-md-1">
          <base-input label="Nombre del spot"
                    placeholder="Nombre"
                    v-model="nombreSpot"
                    required>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5 pr-md-1">
          <base-input label="Tipo de spot">
            <b-form-select v-model="selected">
              <option v-for="tipo in tipoSpot" v-bind:value="tipo.value" :key="tipo.value">
                {{ tipo.texto }}
              </option>
            </b-form-select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5 pr-md-1">
          <b-link href="#" v-show="!isEditFile" @click="isEditFile=true">Audio: {{ file }}</b-link>
          <b-form-file
            v-show="!isEdit||isEditFile"
            v-model="file"
            :state="Boolean(file)"
            placeholder="Seleccione o arrastre un arhivo aquí"
            drop-placeholder="Suelte el archivo aquí"
            :required="!isEdit||isEditFile"
            >
          </b-form-file>
        </div>
      </div>
      <br>

      <div v-if="selected == 'prom'">
        <div class="row">
          <div class="col-md-5 pr-md-1" v-if="!isEdit">
            
            <base-input
              label="Fecha de inicio"
              type="date"
              
              v-model="fechaInicio"
              :required="selected === 'prom'">
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5 pr-md-1">
            <base-input
              :label="`Fecha de finalización`"
              type="date"
              v-model="fechaFin"
              :required="selected === 'prom'">
            </base-input>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5 pr-md-1">
          <input  class="btn btn-md btn-success"
                    type="submit"
                    value="Guardar"
                  >
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import { BaseAlert } from '@/components'
import NotificationTemplate from '../../components/NotificationPlugin/NotificationTemplate'
import spotService from '../../services/SpotServices'
import moment from 'moment'
import handleError from '../../utils/handleErrors'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

export default {
  layout: 'default',
  components: {
    BaseAlert,
    ScaleLoader
  },

  data () {
    const i18labels = this.$t('modulo_spots.biblioteca_spots.alta_spot')
    return {
      isLoading: false,
      textos: {
        title: i18labels.titulo,
        alertOk: i18labels.mensajeAlertaOk,
        mensajeAlertaEditOk: i18labels.mensajeAlertaEditOk
      },
      idSpot: this.$route.params.idSpot,
      nombreSpot: '',
      notificacion: { mensaje: '', tipo: 'error' },
      type: ['', 'danger', 'success'],
      notifications: {
        topCenter: false
      },
      notifIconExc: 'tim-icons icon-alert-circle-exc',
      notifIconOk: 'tim-icons icon-check-2',
      selected: 'inst',
      tipoSpot: [
        { texto: 'Institucional', value: 'inst' },
        { texto: 'Noticias', value: 'noti' },
        { texto: 'Promocional', value: 'prom' }
      ],
      fechaInicio: '',
      fechaFin: '',
      file: null,
      isEdit: false,
      isEditFile:false

    }
  },
  methods: {
    guardar () {
      this.isLoading = true
      if (this.isEdit) {
        this.editarSpot()
      } else {
        this.submitAudioFile()
      }
    },
    submitAudioFile () {
      let fechaDesde
      let fechaHasta
      switch(this.selected){
        case 'prom':
          fechaDesde = this.fechaInicio,
          fechaHasta = this.fechaFin
        break;
        case 'noti':
          fechaDesde = moment().format('YYYY-MM-DD')
          fechaHasta = moment().format('YYYY-MM-DD')
        break; 
        case 'inst': 
        fechaDesde = moment().format('YYYY-MM-DD')
        fechaHasta = moment('9999-01-01').format('YYYY-MM-DD')
        break;

      }
      
      const formdata = new FormData()
      formdata.append('file', this.file)

      spotService.uploadSpot(formdata).then(res => {
        // el res contiene la última parte del path donde se guardó el spot
        spotService.guardarSpot(this.nombreSpot, res, fechaDesde, fechaHasta, this.selected).then(response => {
          // Si da todo ok, mestro mensaje success
          this.notificacion.mensaje = this.textos.alertOk
          this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconOk, 2)
          this.redirectTo('bibliotecaSpot')
          this.isLoading = false
        })
          .catch(err => {
            this.notificacion.mensaje = handleError.errorApi(err)
            spotService.bajaSpot(0, res)
            this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconExc, 1)
            this.isLoading = false
          })
      })
        .catch(err => {
          this.notificacion.mensaje = handleError.errorApi(err)
          this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconExc, 1)
          this.isLoading = false
        })
    },
    editarSpot () {
      this.isLoading = true
      let fechaDesde
      let fechaHasta
      let editSpot = (source)=>{
        spotService.editarSpot(this.idSpot, this.nombreSpot, source,fechaDesde, fechaHasta, this.selected).then(res => {
        this.notificacion.mensaje = this.textos.mensajeAlertaEditOk
        this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconOk, 2)
        this.redirectTo('bibliotecaSpot')
        this.isLoading = false
      })
        .catch(err => {
          this.notificacion.mensaje = handleError.errorApi(err)
          console.log(this.notificacion.mensaje)
          this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconExc, 1)
          this.isLoading = false
        })
      }
      if (this.selected === 'inst') {
        fechaDesde = moment().format('YYYY-MM-DD')
        fechaHasta = moment('9999-01-01').format('YYYY-MM-DD')
      } else {
        fechaDesde = this.fechaInicio,
        fechaHasta = this.fechaFin
      }
      if(this.isEditFile){
       
        const formdata = new FormData()
      formdata.append('file', this.file)

      spotService.uploadSpot(formdata).then(res => {
        editSpot(res)      

      })
      }else{
        editSpot(this.file)
      }
      
    },
    redirectTo (pathComponent) {
      this.$router.push({ path: pathComponent })
    },
    notifyVue (verticalAlign, horizontalAlign, message, icono, tipo) {
      const color = tipo
      this.$notify({
        message: message,
        component: NotificationTemplate,
        icon: icono,
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 8000
      })
    },
    verificarParametros () {
      this.isLoading = true
      if (this.$route.params.idSpot != undefined) {
        
        this.nombreSpot = this.$route.params.nombreSpot
        this.file = this.$route.params.source
        this.fechaInicio = moment(this.$route.params.fechaIni,"dd-mm-yy").format('YYYY-MM-DD')
        this.selected = this.$route.params.tipoSpot

        if (this.selected === 'inst') {
          this.fechaFin = moment().format('YYYY-MM-DD')
        } else {
          this.fechaFin = moment(this.$route.params.fechaFin,"DD-MM-YYYY").format('YYYY-MM-DD')
        }
        this.isEdit = true
      }
      this.isLoading = false
    }
  },
  mounted () {
    this.i18n = this.$i18n
    this.verificarParametros()
  },
  computed: {
    
  }
}

</script>
<style>
</style>
