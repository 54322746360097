<template>
  <card :title="textos.title">
    <b-overlay
      :show="isLoading"
      opacity="0.25"
      no-wrap
      >
      <template #overlay>
        <div class="text-center">
          <scale-loader :loading="true" :color="'#1d8cf8'" :height="'95px'" :mensaje="textoLoader"></scale-loader>
        </div>
      </template>
    </b-overlay>
    <form  id="frmNuevoSpot" accept-charset="UTF-8" role="form" v-on:submit.prevent="guardar" class="form-lock">
      
      
        <div class="row">
        <div class="col-md-5 pr-md-1">
          <base-input label="Nombre del spot"
                    placeholder="Nombre"
                    v-model="nombreSpot"
                    required>
          </base-input>
        </div>
      </div>

      <div class="row" v-show="!isEdit">
        <div class="col-md-5 pr-md-1">
          <base-input label="Tipo de spot">
            <b-form-select v-model="selected">
              <option v-for="tipo in tipoSpot" v-bind:value="tipo.value" :key="tipo.value">
                {{ tipo.texto }}
              </option>
            </b-form-select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5 pr-md-1">
          <b-form-file
            v-model="file"
            :state="Boolean(file)"
            :placeholder="file"
            drop-placeholder="Suelte el archivo aquí"
            :required="!isEdit"
            >
          </b-form-file>
        </div>
      </div>
      <br>

      <div class="row">
        
        <div class="col-md-5 pr-md-1" id="enmbebed">
          <input class="btn btn-md btn-success"
                    type="submit"
                    value="Guardar"
                  >
        </div>
      </div>
      
      
      
    </form>
  </card>
</template>

<script>
import { BaseAlert } from '@/components'
import NotificationTemplate from '../../../components/NotificationPlugin/NotificationTemplate'
import spotService from '../../../services/SpotServices'
import moment from 'moment'
import handleError from '../../../utils/handleErrors'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

export default {
  layout: 'default',
  components: {
    BaseAlert,
    ScaleLoader
  },

  data () {
    const i18labels = this.$t('modulo_spots.biblioteca_spots.alta_spot')
    return {
      isLoading: false,
      textos: {
        title: i18labels.titulo,
        alertOk: i18labels.mensajeAlertaOk,
        mensajeAlertaEditOk: i18labels.mensajeAlertaEditOk
      },
      idSpot: this.$route.params.idSpot,
      nombreSpot: '',
      notificacion: { mensaje: '', tipo: 'error' },
      type: ['', 'danger', 'success'],
      notifications: {
        topCenter: false
      },
      notifIconExc: 'tim-icons icon-alert-circle-exc',
      notifIconOk: 'tim-icons icon-check-2',
      selected: 'noti',
      linkDbx:'',
      tipoSpot: [
        { texto: 'Noticias', value: 'noti' }
      ],
      fechaInicio: moment().format('YYYY-MM-DD'),
      fechaFin: moment().format('YYYY-MM-DD'),
      file: null,
      isEdit: false

    }
  },
  methods: {
 
    guardar () {
      this.isLoading = true
      if (this.isEdit) {
        this.editarNoticia()
      } else {
        this.guardarNoticia()
      }
    },

    guardarNoticia () {
      const formdata = new FormData()
      formdata.append('file', this.file)
      spotService.uploadNoticias(formdata).then(res => {
        // el res contiene la última parte del path donde se guardó el spot
        spotService.guardarSpot(this.nombreSpot, res,null,moment().add(24,'hours').format('YYYY-MM-DD'),'noti').then(response => {
          // Si da todo ok, mestro mensaje success
          this.notificacion.mensaje = this.textos.alertOk
          this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconOk, 2)
          this.redirectTo('Noticias')
          this.isLoading = false
        })
          .catch(err => {
            this.notificacion.mensaje = handleError.errorApi(err)
            spotService.bajaSpot(0, res)
            this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconExc, 1)
            this.isLoading = false
          })
        }).catch(err => {
            this.notificacion.mensaje = handleError.errorApi(err)
            spotService.bajaSpot(0, res)
            this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconExc, 1)
            this.isLoading = false
          })   
      
      
    },
    editarNoticia () {
      const formdata = new FormData()
      formdata.append('file', this.file)
      spotService.uploadNoticias(formdata).then(res => {
        // el res contiene la última parte del path donde se guardó el spot
        spotService.editarNoti(this.idSpot,this.nombreSpot,res,moment().add(24,'hours').format('YYYY-MM-DD')).then(response => {
          // Si da todo ok, mestro mensaje success
          this.notificacion.mensaje = this.textos.alertOk
          this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconOk, 2)
          this.redirectTo('Noticias')
          this.isLoading = false
        })
          .catch(err => {
            this.notificacion.mensaje = handleError.errorApi(err)
            spotService.bajaSpot(0, res)
            this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconExc, 1)
            this.isLoading = false
          })
        }).catch(err => {
            this.notificacion.mensaje = handleError.errorApi(err)
            spotService.bajaSpot(0, res)
            this.notifyVue('top', 'center', this.notificacion.mensaje, this.notifIconExc, 1)
            this.isLoading = false
          })   
      
      
    },
    redirectTo (pathComponent) {
      this.$router.push({ path: pathComponent })
    },
    notifyVue (verticalAlign, horizontalAlign, message, icono, tipo) {
      const color = tipo
      this.$notify({
        message: message,
        component: NotificationTemplate,
        icon: icono,
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 8000
      })
    },
    verificarParametros () {
      this.isLoading = true
      if (this.$route.params.idSpot != undefined) {
        this.nombreSpot = this.$route.params.nombreSpot
        this.file = this.$route.params.file
        this.selected = this.$route.params.tipoSpot
        this.isEdit = true
      }
      this.isLoading = false
    }
  },
  mounted () {
    this.i18n = this.$i18n
    this.verificarParametros()
    
   
  },
  computed: {

  }
}

</script>
<style>
</style>
