import api from './api'

const clienteProgramacionSpotService = {}
const dias = [0,1,2,3,4,5,6]

// Services para el controller clienteProgramacionSpot
// Ver por que no funciona un get con varios parametros (por ahora hice la petición por post cuando es un get)
clienteProgramacionSpotService.getProgramacionesByProg = async function (codigoProgramacion,horaDesde, escuchador) {
  let response = []
  const list = []
  for(let numeroDia of dias){
    response.push(
      api.post('/clienteprogramacionspot/getSpots', {codigoProgramacion,numeroDia,horaDesde, escuchador})
      )
    }
   
    return Promise.all(response).then((resp)=>
      {
        const progString = resp.forEach((prog)=>{
          if(prog.data.length>0){
            prog.data.forEach(sp=>list.push(sp))
            }
          })
        
        return JSON.stringify(list)
      }
    )
    .then((data)=>localStorage.spotsProgramados=data)  
  
}

// post para guardar un spot en el calendar
clienteProgramacionSpotService.guardarProgSpot = async function (spotsProg) {
  let resPromises = []
  spotsProg.forEach((spoProg)=>{
    resPromises.push(api.post('/clienteprogramacionspot/', spoProg).then(res => res.data))
  })

   let result = await Promise.all(resPromises)
   return result
    
}

// put para cambiar el modificar un spot en el calendar
clienteProgramacionSpotService.editarProgSpot = async function (codigo, codigoProgramacion, codigoSpot, numeroDia, horaDesde, horaHasta, orden) {
  
  return api.put('/clienteprogramacionspot/', {

    codigo, codigoProgramacion, codigoSpot, numeroDia, horaDesde, horaHasta, orden

  }).then(res => res.data)
}

clienteProgramacionSpotService.permutarProgSpot = async function (pdata) {
    
  return api.post('/clienteprogramacionspot/moverSpot', {...pdata}).then(res => res.data)
}

clienteProgramacionSpotService.eliminarProgSpot = async function (codigoProgramacion, usuarioSpot) {
  return api.delete(`clienteprogramacionspot/${codigoProgramacion}/${usuarioSpot}`
  ).then(res => res.data)
}

clienteProgramacionSpotService.getProgSpotSucursal = async function (codigoProgramacion) {
  return api.get(`clienteprogramacionspot/sucursales/${codigoProgramacion}`
  ).then(res => res.data)
}

export default clienteProgramacionSpotService
