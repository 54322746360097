<template>
  <div>
    <b-overlay
        :show="isLoading"
        opacity="0.4"
        no-wrap
        >
        <template #overlay>
          <div class="text-center">
            <scale-loader :loading="true" :color="'#1d8cf8'" :height="'95px'"></scale-loader>
          </div>
        </template>
      </b-overlay>
      <form  id="miNegocio" accept-charset="UTF-8" role="form" v-on:submit.prevent="guardarDatos" class="form-lock">
        <div class="row">
          <card class="col-12">
            <h5>{{subtitulos.pass}}</h5>
            <div class="row">
              <div class="col-md-6 pr-md-1">
                <base-input label="Password (Actual)"
                          placeholder="Password actual"
                          v-model="passwordActual"
                          type="password"
                          >
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 pr-md-1">
                <base-input label="Nueva password"
                          v-model="passwordNueva1"
                          placeholder="Nueva password"
                          type="password"
                          >
                </base-input>
              </div>

              <div class="col-md-6 pr-md-1">
                <base-input label="Confirmar nueva password"
                          placeholder="Nueva password"
                          v-model="passwordNueva2"
                          type="password"
                          >
                </base-input>
              </div>
            </div>
          </card>
        </div>

        <div class="row">
          <card class="col-12">
            <div class="row">
              <div class="col-4">
                <div class="options-modo-reproduccion">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="title mt-3 mb-2">{{subtitulos.modoReproduccion }}</h5>
                      <br>
                      <div id="inputRadio">
                        <b-form-radio-group
                          v-model="modoReproduccion.selected"
                          :options="modoReproduccion.options"
                          value-field="value"
                          text-field="modo"
                          disabled-field="notEnabled"
                        ></b-form-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-8">
                <div>
                  <span><i class="tim-icons icon-shape-star text-success"></i></span>
                  <span class="ml-2 text-warning">{{referencias.modoNeuro}}</span>
                </div>
                <div class="mt-3">
                  <span><i class="tim-icons icon-shape-star text-success"></i></span>
                  <span class="ml-2 text-warning">{{referencias.modoRadio}}</span>
                </div>
              </div>
            </div>
          </card>
        </div>

        <div class="row">
          <card class="col-12">
              <h5>{{subtitulos.diasHabiles}}</h5>
              <div class="col-md-12 pr-md-1">
                <div class="row">
                  <b-form-group>
                    <b-form-checkbox-group
                      v-model="dias.selected"
                      :options="dias.options"
                      class="mb-3"
                      value-field="value"
                      text-field="dia"
                    ></b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <h5>{{subtitulos.horario}}</h5>
              <div class="row">
                <div class="col-md-6 pr-md-1">
                    <base-input
                      label="Desde"
                      type="time"
                      v-model="horaDesde"
                    >
                    </base-input>
                </div>
                <div class="col-md-6 pr-md-1">
                    <base-input
                      label="Hasta"
                      type="time"
                      v-model="horaHasta"
                    >
                    </base-input>
                </div>
              </div>
            </card>
          </div>

        <div class="row">
          <div class="col-md-5 pr-md-1">
            <input class="btn btn-md btn-success"
                      type="submit"
                      value="Guardar"
                    >
          </div>
        </div>
      </form>

  </div>
</template>

<script>
import { BaseAlert } from '@/components'
import NotificationTemplate from '../components/NotificationPlugin/NotificationTemplate'
import VueBootstrap4Table from 'vue-bootstrap4-table'
import DiasServices from '../services/DiaHabilService'
import CliHorarioServices from '../services/ClienteHorarioServices'
import ClienteService from '../services/ClienteServices'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import clienteHorarioService from '../services/ClienteHorarioServices'
import clienteModoSpotService from '../services/ClienteModoReproduccionSpot'

export default {
  layout: 'default',
  components: {
    BaseAlert,
    VueBootstrap4Table,
    ScaleLoader
  },

  data () {
    const i18labels = this.$t('modulo_miNegocio')
    return {
      title: i18labels.titulo,
      passwordActual: '',
      passwordNueva1: '',
      passwordNueva2: '',
      subtitulos: {
        pass: i18labels.subtituloCambioPass,
        diasHabiles: i18labels.subtituloDiasHabiles,
        horario: i18labels.subtituloHorario,
        modoReproduccion: i18labels.subtituloModoReproduccion
      },
      referencias: {
        modoNeuro: i18labels.referencias.textoModoNeuro,
        modoRadio: i18labels.referencias.textoModoRadio
      },
      dias: {
        selected: [],
        options: [
          { dia: 'Domingo', value: 0 }, { dia: 'Lunes', value: 1 },
          { dia: 'Martes', value: 2 }, { dia: 'Miercoles', value: 3 },
          { dia: 'Jueves', value: 4 }, { dia: 'Viernes', value: 5 },
          { dia: 'Sábado', value: 6 }
        ]
      },
      modoReproduccion: {
        selected: null,
        options: [
          { modo: 'Neuro', value: 'neuro' }, { modo: 'Radio', value: 'radio' }
        ]
      },
      horaDesde: '',
      horaHasta: '',
      type: ['', 'danger', 'success'],
      notifications: {
        topCenter: false
      },
      notificacion: { mensaje: '', tipo: 'error' },
      config: {
        per_page: 5, // numero de filas a mostrar por cada página
        per_page_options: [],
        show_refresh_button: false,
        show_reset_button: false,
        highlight_row_hover_color: '#003962'
      },
      isLoading: false
    }
  },
  methods: {

    guardarDatos () {
      this.isLoading = true
      if (this.horaDesde != '' && this.horaHasta != '') {
        ClienteService.setDatosCuenta(this.modoReproduccion.selected, this.dias.selected.toString(), this.horaDesde, this.horaHasta,
          this.passwordActual, this.passwordNueva1, this.passwordNueva2).then(res => {
          this.notificacion.mensaje = 'Sus datos se actualizaron correctamente'
          this.limpiarCampos()
          this.isLoading = false
          this.notifyVue('top', 'center', this.notificacion.mensaje, 2)
        })
          .catch(err => {
            this.handleError(err)
            this.isLoading = false
            this.notifyVue('top', 'center', this.notificacion.mensaje, 1)
          })
      } else {
        this.notificacion.mensaje = 'Ingrese el horario de su negocio'
        this.notifyVue('top', 'center', this.notificacion.mensaje, 1)
      }
    },
    getDiasHabilesCliente () {
      const dias = []
      this.isLoading = true
      DiasServices.get().then(res => {
        res.forEach(element => {
          dias.push(element.cliDha_codigoDia)
        })
        this.dias.selected = dias
        this.isLoading = false
      })
    },
    getHorarioCliente () {
      // traer los días que tiene seleccionado el cliente
      this.isLoading = true
      CliHorarioServices.get().then(res => {
        this.horaDesde = res.cliHor_horaDesde
        this.horaHasta = res.cliHor_horaHasta
        this.isLoading = false
      })
    },
    getModoRepSpot () {
      clienteModoSpotService.get().then(res => {
        this.modoReproduccion.selected = res.climod_modo
      })
    },
    limpiarCampos () {
      this.passwordActual = '',
      this.passwordNueva1 = '',
      this.passwordNueva2 = ''
    },
    handleError (err) {
      if (!err.response) {
        this.notificacion.mensaje = 'No se ha recibido respuesta del servidor. Int&eacute;ntelo nuevamente m&aacute;s tarde.'
      } else {
        if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
          this.notificacion.mensaje = err.response.data.errorMessage
        } else {
          const erro = []
          console.log(err.response.data.split('at'))
          switch (err.response.status) {
            case 502:
              this.notificacion.mensaje = 'El servidor no se encuentra disponible.'
              break
            default:
              /* si obtengo un system.exception, hago un split para poder obtener la primer parte
                *luego, de la primer parte, con un substr para extraer lo que sigue de
                *"system.exception" (17 caracteres)
                */
              let error = []
              error = (err.response.data.split('at'))
              this.notificacion.mensaje = error[0].substr(18)
          }
        }
      }
    },
    notifyVue (verticalAlign, horizontalAlign, message, tipo) {
      const color = tipo
      this.$notify({
        message: message,

        icon: 'tim-icons icon-alert-circle-exc',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 5000
      })
    }
  },
  created () {
    this.i18n = this.$i18n
    this.getDiasHabilesCliente()
  },
  mounted () {
    this.getHorarioCliente()
    this.getModoRepSpot()
  },
  computed: {

  }
}

</script>
<style>
</style>
