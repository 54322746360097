import api from './api'

const clienteProgramacionRadioService = {}

// Services para el controller clienteProgramacionRadio
clienteProgramacionRadioService.getProgramacionesByProg = async function (codigoProgramacion) {
  return api.get(`/clienteprogramacionradio/${codigoProgramacion}`).then(res => res.data)
}

// post para guardar una playlist en el calendario
clienteProgramacionRadioService.guardarProgRadio = async function (codigoProgramacion, codigoRadio, numeroDia, horaDesde, horaHasta) {
  return api.post('/clienteprogramacionradio/', {

    codigoProgramacion, codigoRadio, numeroDia, horaDesde, horaHasta

  }).then(res => res.data)
}

// put para cambiar el modificar el de una radio
clienteProgramacionRadioService.editarProgRadio = async function (codigo, codigoProgramacion, codigoRadio, numeroDia, horaDesde, horaHasta) {
  return api.put('/clienteprogramacionradio/', {

    codigo, codigoProgramacion, codigoRadio, numeroDia, horaDesde, horaHasta

  }).then(res => res.data)
}

clienteProgramacionRadioService.bajaProgramacionRadio = async function (codigoProgramacionRadio) {
  return api.delete(`/clienteprogramacionradio/${codigoProgramacionRadio}`
  ).then(res => res.data)
}
export default clienteProgramacionRadioService
